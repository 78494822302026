import { makeApi, Zodios, type ZodiosOptions } from '@zodios/core';
import { z } from 'zod';

type Company = Partial<{
  id: string;
  version: number;
  entityType: string;
  entityStatus: string;
  changedByAdmin: boolean;
  changedByAdminUser: string;
  changedByCustomer: boolean;
  createdDate: string;
  createdBy: string;
  lastUpdatedDate: string;
  lastUpdatedBy: string;
  email: string;
  userCustomerRole: string;
  pepSanction: PEPSanction;
  capabilities: Array<string>;
  customerName: string;
  companyName: string;
  companyNumber: string;
  businessNumber: string;
  companyExtractFileId: UUID;
  registeredOffice: Address;
  principalPlaceOfBusiness: Address;
  listedExchange: string;
  ticker: string;
  phone: Phone;
  ultimateBeneficialOwners: Array<PersonReference>;
  directors: Array<PersonReference>;
  wholesaleCertificateHolder: PersonReference;
  wholesaleCertificate: WholesaleCertificate;
  fileId: UUID;
}>;
type PEPSanction = Partial<{
  id: UUID;
  version: number;
  customerId: UUID;
  pepStatus: boolean;
  sanctionStatus: boolean;
  lastUpdatedDatetime: OffsetDateTime;
  lastUpdatedBy: string;
  commentText: string;
}>;
type UUID = string;
type OffsetDateTime = string;
type Address = Partial<{
  addressType: string;
  addressStatus: string;
  unitNumber: string;
  streetNumber: string;
  streetName: string;
  streetType: string;
  suburb: string;
  postcode: string;
  city: string;
  state: string;
  countryCode: string;
}>;
type Phone = Partial<{
  phoneType: string;
  phoneStatus: string;
  countryCode: string;
  phoneNumber: string;
}>;
type Person = Partial<{
  id: string;
  version: number;
  entityType: string;
  entityStatus: string;
  changedByAdmin: boolean;
  changedByAdminUser: string;
  changedByCustomer: boolean;
  createdDate: string;
  createdBy: string;
  lastUpdatedDate: string;
  lastUpdatedBy: string;
  email: string;
  userCustomerRole: string;
  pepSanction: PEPSanction;
  capabilities: Array<string>;
  customerName: string;
  firstName: string;
  middleName: string;
  familyName: string;
  gender: string;
  dateOfBirth: string;
  address: Address;
  phone: Phone;
  driverLicence: DriverLicence;
  medicareCard: MedicareCard;
  otherIdentityDocument: OtherIdentityDocument;
  passport: Passport;
  wholesaleCertificate: WholesaleCertificate;
  identityDocumentTypeList: Array<IdentityDocumentType>;
  identityDocument: IIdentityDocument;
}>;
type DriverLicence = Partial<{
  licenceType: string;
  licenceStatus: string;
  licenceCountryOfIssue: string;
  licenceState: string;
  licenceNumber: string;
  licenceCardNumber: string;
  licenceVersionNumber: string;
  licenceName: string;
  licenceExpiry: string;
  status: string;
}>;
type MedicareCard = Partial<{
  medicareStatus: string;
  medicareValidationMethod: string;
  medicareCardNumber: string;
  medicareCardColour: string;
  medicareCardExpiry: string;
  medicareCardIndividualRefNumber: string;
  medicareCardFullName: string;
  status: string;
}>;
type OtherIdentityDocument = Partial<{
  otherDocumentType: string;
  documentStatus: string;
  documentIdentifier: string;
  countryOfIssue: string;
  dateOfIssue: string;
  dateOfExpiry: string;
  status: string;
}>;
type Passport = Partial<{
  passportType: string;
  passportStatus: string;
  passportValidationMethod: string;
  passportNumber: string;
  passportName: string;
  passportExpiry: string;
  passportCountryOfIssue: string;
  status: string;
}>;
type WholesaleCertificate = Partial<{
  id: UUID;
  version: number;
  fileId: UUID;
  supportDocumentSubType: SupportDocumentSubType;
  status: EntityStatus;
  createdDate: OffsetDateTime;
  createdBy: string;
  lastUpdatedDate: OffsetDateTime;
  lastUpdatedBy: string;
  customerId: UUID;
  keyAttributeName: string;
  documentType: 'RealEstateSupportDocument' | 'OtherSupportDocument' | 'WholesaleCertificate';
  dateIssued: LocalDate;
  dateOfExpiry: LocalDate;
  accountantNumber: string;
  certificateStatus: EntityStatus;
}>;
type SupportDocumentSubType =
  | 'CertificateOfTitle'
  | 'NationalVendorDeclarationForLivestock'
  | 'Other'
  | 'RatesNotice'
  | 'ValuationNotice'
  | 'WholesaleCertificate';
type EntityStatus = 'Draft' | 'InProgress' | 'Rejected' | 'Verified';
type LocalDate = string;
type IdentityDocumentType = 'Passport' | 'DriverLicence' | 'OtherIdentityDocument' | 'MedicareCard';
type IIdentityDocument = Partial<{
  country: AuthorizedIssuer;
  status: string;
  documentType: string;
}>;
type AuthorizedIssuer = Partial<{
  name: string;
  alpha2: string;
  alpha3: string;
}>;
type Customer = Person | Company | Trust | SoleTrader | Partnership;
type CustomerReference = Partial<{
  id: string;
  customer: Customer;
}>;
type MetaAttribute = Partial<{
  name: string;
  dataType: string;
  label: string;
  description: string;
  accessLevel: AccessLevel;
  createOnly: boolean;
  readOnly: boolean;
  readRoles: Array<string>;
  writeRoles: Array<string>;
  ruleList: Array<MetaRule>;
}>;
type AccessLevel = 'RestrictedToCustomer' | 'RestrictedToClient' | 'Unrestricted';
type MetaEntity = Partial<{
  entityType: string;
  attributeList: Array<MetaAttribute>;
  ruleList: Array<MetaRule>;
}>;
type MetaRule = Partial<{
  metaEntity: MetaEntity;
  metaAttribute: MetaAttribute;
  ruleType: string;
}>;
type Organisation = Partial<{
  id: string;
  version: number;
  organisationType: 'Client';
  entityId: string;
  organisationStatus: 'Active' | 'Inactive';
  organisationName: string;
  address: Address;
  children: Array<Organisation>;
}>;
type Partnership = Partial<{
  id: string;
  version: number;
  entityType: string;
  entityStatus: string;
  changedByAdmin: boolean;
  changedByAdminUser: string;
  changedByCustomer: boolean;
  createdDate: string;
  createdBy: string;
  lastUpdatedDate: string;
  lastUpdatedBy: string;
  email: string;
  userCustomerRole: string;
  pepSanction: PEPSanction;
  capabilities: Array<string>;
  customerName: string;
  partnershipName: string;
  businessNumber: string;
  principalPlaceOfBusiness: Address;
  partnershipAgreementFileId: UUID;
  partners: Array<CustomerReference>;
  wholesaleCertificateHolder: PersonReference;
  wholesaleCertificate: WholesaleCertificate;
}>;
type PersonReference = Partial<{
  id: string;
  customer: Customer;
}>;
type SoleTrader = Partial<{
  id: string;
  version: number;
  entityType: string;
  entityStatus: string;
  changedByAdmin: boolean;
  changedByAdminUser: string;
  changedByCustomer: boolean;
  createdDate: string;
  createdBy: string;
  lastUpdatedDate: string;
  lastUpdatedBy: string;
  email: string;
  userCustomerRole: string;
  pepSanction: PEPSanction;
  capabilities: Array<string>;
  customerName: string;
  soleTraderName: string;
  businessNumber: string;
  principalPlaceOfBusiness: Address;
  individual: PersonReference;
  wholesaleCertificateHolder: PersonReference;
  wholesaleCertificate: WholesaleCertificate;
}>;
type Trust = Partial<{
  id: string;
  version: number;
  entityType: string;
  entityStatus: string;
  changedByAdmin: boolean;
  changedByAdminUser: string;
  changedByCustomer: boolean;
  createdDate: string;
  createdBy: string;
  lastUpdatedDate: string;
  lastUpdatedBy: string;
  email: string;
  userCustomerRole: string;
  pepSanction: PEPSanction;
  capabilities: Array<string>;
  customerName: string;
  address: Address;
  phone: Phone;
  businessNumber: string;
  trustName: string;
  businessNameOfTrustee: string;
  deedFileId: UUID;
  wholesaleCertificateHolder: PersonReference;
  ultimateBeneficialOwners: Array<PersonReference>;
  trustees: Array<CustomerReference>;
  settlors: Array<PersonReference>;
  trustType: string;
  wholesaleCertificate: WholesaleCertificate;
}>;

const UUID = z.string();
const Address = z
  .object({
    addressType: z.string(),
    addressStatus: z.string(),
    unitNumber: z.string(),
    streetNumber: z.string(),
    streetName: z.string(),
    streetType: z.string(),
    suburb: z.string(),
    postcode: z.string(),
    city: z.string(),
    state: z.string(),
    countryCode: z.string(),
  })
  .partial()
  .passthrough();
const Phone = z
  .object({
    phoneType: z.string(),
    phoneStatus: z.string(),
    countryCode: z.string(),
    phoneNumber: z.string(),
  })
  .partial()
  .passthrough();
const CreateCompanyRequest = z
  .object({
    entityType: z.string(),
    entityStatus: z.string(),
    companyName: z.string(),
    companyNumber: z.string(),
    businessNumber: z.string(),
    companyExtractFileId: UUID.regex(
      /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/,
    ).uuid(),
    registeredOffice: Address,
    principalPlaceOfBusiness: Address,
    listedExchange: z.string(),
    ticker: z.string(),
    phone: Phone,
    ultimateBeneficialOwnerIds: z.array(z.string()),
    directorIds: z.array(z.string()),
  })
  .partial()
  .passthrough();
const OffsetDateTime = z.string();
const PEPSanction = z
  .object({
    id: UUID,
    version: z.number().int(),
    customerId: UUID,
    pepStatus: z.boolean(),
    sanctionStatus: z.boolean(),
    lastUpdatedDatetime: OffsetDateTime,
    lastUpdatedBy: z.string(),
    commentText: z.string(),
  })
  .partial()
  .passthrough();
const DriverLicence = z
  .object({
    licenceType: z.string(),
    licenceStatus: z.string(),
    licenceCountryOfIssue: z.string(),
    licenceState: z.string(),
    licenceNumber: z.string(),
    licenceCardNumber: z.string(),
    licenceVersionNumber: z.string(),
    licenceName: z.string(),
    licenceExpiry: z.string(),
    status: z.string(),
  })
  .partial()
  .passthrough();
const MedicareCard = z
  .object({
    medicareStatus: z.string(),
    medicareValidationMethod: z.string(),
    medicareCardNumber: z.string(),
    medicareCardColour: z.string(),
    medicareCardExpiry: z.string(),
    medicareCardIndividualRefNumber: z.string(),
    medicareCardFullName: z.string(),
    status: z.string(),
  })
  .partial()
  .passthrough();
const OtherIdentityDocument = z
  .object({
    otherDocumentType: z.string(),
    documentStatus: z.string(),
    documentIdentifier: z.string(),
    countryOfIssue: z.string(),
    dateOfIssue: z.string(),
    dateOfExpiry: z.string(),
    status: z.string(),
  })
  .partial()
  .passthrough();
const Passport = z
  .object({
    passportType: z.string(),
    passportStatus: z.string(),
    passportValidationMethod: z.string(),
    passportNumber: z.string(),
    passportName: z.string(),
    passportExpiry: z.string(),
    passportCountryOfIssue: z.string(),
    status: z.string(),
  })
  .partial()
  .passthrough();
const SupportDocumentSubType = z.enum([
  'CertificateOfTitle',
  'NationalVendorDeclarationForLivestock',
  'Other',
  'RatesNotice',
  'ValuationNotice',
  'WholesaleCertificate',
]);
const EntityStatus = z.enum(['Draft', 'InProgress', 'Rejected', 'Verified']);
const LocalDate = z.string();
const WholesaleCertificate = z
  .object({
    id: UUID,
    version: z.number().int(),
    fileId: UUID.regex(
      /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/,
    ).uuid(),
    supportDocumentSubType: SupportDocumentSubType,
    status: EntityStatus,
    createdDate: OffsetDateTime,
    createdBy: z.string(),
    lastUpdatedDate: OffsetDateTime,
    lastUpdatedBy: z.string(),
    customerId: UUID,
    keyAttributeName: z.string(),
    documentType: z.enum([
      'RealEstateSupportDocument',
      'OtherSupportDocument',
      'WholesaleCertificate',
    ]),
    dateIssued: LocalDate,
    dateOfExpiry: LocalDate,
    accountantNumber: z.string(),
    certificateStatus: EntityStatus,
  })
  .partial()
  .passthrough();
const IdentityDocumentType = z.enum([
  'Passport',
  'DriverLicence',
  'OtherIdentityDocument',
  'MedicareCard',
]);
const AuthorizedIssuer = z
  .object({ name: z.string(), alpha2: z.string(), alpha3: z.string() })
  .partial()
  .passthrough();
const IIdentityDocument = z
  .object({ country: AuthorizedIssuer.nullable(), status: z.string(), documentType: z.string() })
  .partial()
  .passthrough();
const Person = z
  .object({
    id: z.string(),
    version: z.number().int(),
    entityType: z.string(),
    entityStatus: z.string(),
    changedByAdmin: z.boolean(),
    changedByAdminUser: z.string(),
    changedByCustomer: z.boolean(),
    createdDate: z.string(),
    createdBy: z.string(),
    lastUpdatedDate: z.string(),
    lastUpdatedBy: z.string(),
    email: z.string(),
    userCustomerRole: z.string(),
    pepSanction: PEPSanction,
    capabilities: z.array(z.string()),
    customerName: z.string(),
    firstName: z.string(),
    middleName: z.string(),
    familyName: z.string(),
    gender: z.string(),
    dateOfBirth: z.string(),
    address: Address,
    phone: Phone,
    driverLicence: DriverLicence,
    medicareCard: MedicareCard,
    otherIdentityDocument: OtherIdentityDocument,
    passport: Passport,
    wholesaleCertificate: WholesaleCertificate,
    identityDocumentTypeList: z.array(IdentityDocumentType),
    identityDocument: IIdentityDocument,
  })
  .partial()
  .passthrough();
const PersonReference: z.ZodType<PersonReference> = z.lazy(() =>
  z.object({ id: z.string(), customer: Customer }).partial().passthrough(),
);
const Company: z.ZodType<Company> = z.lazy(() =>
  z
    .object({
      id: z.string(),
      version: z.number().int(),
      entityType: z.string(),
      entityStatus: z.string(),
      changedByAdmin: z.boolean(),
      changedByAdminUser: z.string(),
      changedByCustomer: z.boolean(),
      createdDate: z.string(),
      createdBy: z.string(),
      lastUpdatedDate: z.string(),
      lastUpdatedBy: z.string(),
      email: z.string(),
      userCustomerRole: z.string(),
      pepSanction: PEPSanction,
      capabilities: z.array(z.string()),
      customerName: z.string(),
      companyName: z.string(),
      companyNumber: z.string(),
      businessNumber: z.string(),
      companyExtractFileId: UUID.regex(
        /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/,
      ).uuid(),
      registeredOffice: Address,
      principalPlaceOfBusiness: Address,
      listedExchange: z.string(),
      ticker: z.string(),
      phone: Phone,
      ultimateBeneficialOwners: z.array(PersonReference),
      directors: z.array(PersonReference),
      wholesaleCertificateHolder: PersonReference,
      wholesaleCertificate: WholesaleCertificate,
      fileId: UUID,
    })
    .partial()
    .passthrough(),
);
const CustomerReference: z.ZodType<CustomerReference> = z.lazy(() =>
  z.object({ id: z.string(), customer: Customer }).partial().passthrough(),
);
const Trust: z.ZodType<Trust> = z.lazy(() =>
  z
    .object({
      id: z.string(),
      version: z.number().int(),
      entityType: z.string(),
      entityStatus: z.string(),
      changedByAdmin: z.boolean(),
      changedByAdminUser: z.string(),
      changedByCustomer: z.boolean(),
      createdDate: z.string(),
      createdBy: z.string(),
      lastUpdatedDate: z.string(),
      lastUpdatedBy: z.string(),
      email: z.string(),
      userCustomerRole: z.string(),
      pepSanction: PEPSanction,
      capabilities: z.array(z.string()),
      customerName: z.string(),
      address: Address,
      phone: Phone,
      businessNumber: z.string(),
      trustName: z.string(),
      businessNameOfTrustee: z.string(),
      deedFileId: UUID.regex(
        /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/,
      ).uuid(),
      wholesaleCertificateHolder: PersonReference,
      ultimateBeneficialOwners: z.array(PersonReference),
      trustees: z.array(CustomerReference),
      settlors: z.array(PersonReference),
      trustType: z.string(),
      wholesaleCertificate: WholesaleCertificate,
    })
    .partial()
    .passthrough(),
);
const SoleTrader: z.ZodType<SoleTrader> = z.lazy(() =>
  z
    .object({
      id: z.string(),
      version: z.number().int(),
      entityType: z.string(),
      entityStatus: z.string(),
      changedByAdmin: z.boolean(),
      changedByAdminUser: z.string(),
      changedByCustomer: z.boolean(),
      createdDate: z.string(),
      createdBy: z.string(),
      lastUpdatedDate: z.string(),
      lastUpdatedBy: z.string(),
      email: z.string(),
      userCustomerRole: z.string(),
      pepSanction: PEPSanction,
      capabilities: z.array(z.string()),
      customerName: z.string(),
      soleTraderName: z.string(),
      businessNumber: z.string(),
      principalPlaceOfBusiness: Address,
      individual: PersonReference,
      wholesaleCertificateHolder: PersonReference,
      wholesaleCertificate: WholesaleCertificate,
    })
    .partial()
    .passthrough(),
);
const Partnership: z.ZodType<Partnership> = z.lazy(() =>
  z
    .object({
      id: z.string(),
      version: z.number().int(),
      entityType: z.string(),
      entityStatus: z.string(),
      changedByAdmin: z.boolean(),
      changedByAdminUser: z.string(),
      changedByCustomer: z.boolean(),
      createdDate: z.string(),
      createdBy: z.string(),
      lastUpdatedDate: z.string(),
      lastUpdatedBy: z.string(),
      email: z.string(),
      userCustomerRole: z.string(),
      pepSanction: PEPSanction,
      capabilities: z.array(z.string()),
      customerName: z.string(),
      partnershipName: z.string(),
      businessNumber: z.string(),
      principalPlaceOfBusiness: Address,
      partnershipAgreementFileId: UUID.regex(
        /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/,
      ).uuid(),
      partners: z.array(CustomerReference),
      wholesaleCertificateHolder: PersonReference,
      wholesaleCertificate: WholesaleCertificate,
    })
    .partial()
    .passthrough(),
);
const Customer: z.ZodType<Customer> = z.lazy(() =>
  z.union([Person, Company, Trust, SoleTrader, Partnership]),
);
const ResultType = z.enum(['Info', 'Warning', 'Error']);
const ResultScope = z.enum(['Field', 'Form']);
const ValidationResult = z
  .object({
    path: z.string(),
    name: z.string(),
    resultType: ResultType,
    resultName: z.string(),
    resultScope: ResultScope,
    message: z.string(),
  })
  .partial()
  .passthrough();
const CustomerResponse = z
  .object({ customer: Customer, validationResults: z.array(ValidationResult) })
  .partial()
  .passthrough();
const UpdateCompanyRequest = z
  .object({
    entityType: z.string(),
    entityStatus: z.string(),
    companyName: z.string(),
    companyNumber: z.string(),
    businessNumber: z.string(),
    companyExtractFileId: UUID.regex(
      /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/,
    ).uuid(),
    registeredOffice: Address,
    principalPlaceOfBusiness: Address,
    listedExchange: z.string(),
    ticker: z.string(),
    phone: Phone,
    ultimateBeneficialOwnerIds: z.array(z.string()),
    directorIds: z.array(z.string()),
    id: z.string(),
    version: z.number().int(),
  })
  .partial()
  .passthrough();
const DocumentType = z.enum([
  'DriverLicence',
  'MedicareCard',
  'OtherIdentityDocument',
  'Passport',
  'CompanyExtract',
  'PartnershipAgreement',
  'TrustDeed',
  'WholesaleCertificate',
  'RealEstateSupportDocument',
  'OtherSupportDocument',
]);
const postV1companyIdcompanyExtract_Body = z
  .object({ documentType: DocumentType, fileType: z.string(), file: z.instanceof(File) })
  .partial()
  .passthrough();
const CustomerListResponse = z
  .object({ customerList: z.array(Customer) })
  .partial()
  .passthrough();
const CustomerRequest = z
  .object({
    customer: Customer,
    entityStatus: z.enum(['InProgress', 'Verified', 'Rejected', 'Draft']).default('InProgress'),
    addToUser: z.boolean(),
    addToUserWhoHasEmail: z.string(),
    reason: z.string(),
    suppressEmailNotification: z.boolean().default(false),
  })
  .partial()
  .passthrough();
const ReviewerUserCustomerRole = z
  .object({
    email: z.string().email(),
    username: z.string(),
    notes: z.string(),
    userPrimaryIndividualId: UUID,
    userPrimaryIndividualName: z.string(),
  })
  .partial()
  .passthrough();
const SyncReviewersRequest = z
  .object({ reviewers: z.array(ReviewerUserCustomerRole) })
  .partial()
  .passthrough();
const IValidatable = z.object({}).partial().passthrough();
const ValidationFailedResponse = z
  .object({
    request: IValidatable,
    validationResults: z.array(
      z
        .object({
          path: z.string(),
          name: z.string(),
          resultType: ResultType,
          resultName: z.string(),
          resultScope: ResultScope,
          message: z.string(),
        })
        .partial()
        .passthrough(),
    ),
  })
  .partial()
  .passthrough();
const UserCustomerRole = z.enum(['Controller', 'Viewer']);
const WalletHolderUserCustomerRole = z
  .object({
    email: z.string().email().optional(),
    username: z.string().optional(),
    notes: z.string().optional(),
    userPrimaryIndividualId: UUID.optional(),
    userPrimaryIndividualName: z.string().optional(),
    systemAssignedRole: UserCustomerRole.optional(),
    adminAssignedRole: UserCustomerRole.nullable(),
  })
  .passthrough();
const SyncWalletHoldersRequest = z
  .object({ walletHolders: z.array(WalletHolderUserCustomerRole) })
  .partial()
  .passthrough();
const UserCustomerRoleSource = z.enum(['Manual', 'Owner', 'CustomerReference']);
const UserCustomerRoleResponse = z
  .object({
    email: z.string().email(),
    username: z.string(),
    role: UserCustomerRole,
    source: UserCustomerRoleSource,
    notes: z.string(),
    userPrimaryIndividualId: z
      .string()
      .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
      .uuid(),
    userPrimaryIndividualName: z.string(),
  })
  .partial()
  .passthrough();
const UpdateEntityStatusRequest = z
  .object({ entityStatus: EntityStatus, reason: z.string() })
  .partial()
  .passthrough();
const UpdateIdentityDocumentStatusRequest = z
  .object({
    identityDocumentType: z.string(),
    identityDocumentStatus: z.string(),
    reason: z.string(),
  })
  .partial()
  .passthrough();
const CustomerAddUserRoleRequest = z
  .object({
    username: z.string(),
    email: z.string(),
    role: UserCustomerRole.default('Controller'),
    source: z.enum(['Manual', 'Owner']),
    notes: z.string(),
  })
  .partial()
  .passthrough();
const ScanResultStatus = z.enum([
  'SUCCESS',
  'DOCUMENT_LOOKUP_FAILURE',
  'SCAN_FAILURE',
  'DOCUMENT_UPDATE_FAILURE',
  'NOTHING_TO_SCAN',
  'VERIFICATION_FAILURE',
]);
const ScanResultErrorDetail = z
  .object({ code: z.string(), message: z.string() })
  .partial()
  .passthrough();
const IdentityDocumentScanResult = z
  .object({
    customerId: UUID,
    documentType: z.enum(['Passport', 'MedicareCard', 'DriverLicence']),
    fileIds: z.array(
      z
        .string()
        .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
        .uuid(),
    ),
    status: ScanResultStatus,
    scanTime: OffsetDateTime,
    errorDetails: z.array(ScanResultErrorDetail),
  })
  .partial()
  .passthrough();
const ScanDocumentApiResponse = z
  .object({
    type: IdentityDocumentType,
    passport: Passport,
    driverLicence: DriverLicence,
    medicareCard: MedicareCard,
    medicareCardOwners: z.record(z.string()),
  })
  .partial()
  .passthrough();
const postV1file_Body = z
  .object({
    entityId: UUID,
    documentType: DocumentType,
    fileType: z.string(),
    file: z.instanceof(File),
  })
  .partial()
  .passthrough();
const FileUploadResponse = z
  .object({ fileId: UUID, validationResults: z.array(ValidationResult) })
  .partial()
  .passthrough();
const FileType = z.enum([
  'DriverLicence',
  'MedicareCard',
  'OtherIdentityDocument',
  'Passport',
  'CompanyExtract',
  'PartnershipAgreement',
  'TrustDeed',
  'WholesaleCertificate',
  'RealEstateSupportDocument',
  'OtherSupportDocument',
  'IdPortrait',
]);
const PresignApiRequest = z
  .object({
    entityId: UUID.regex(
      /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/,
    ).uuid(),
    documentType: FileType,
    filenameList: z.array(z.string()),
  })
  .partial()
  .passthrough();
const PresignUrl = z
  .object({
    entityId: UUID.regex(
      /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/,
    ).uuid(),
    fileId: UUID.regex(
      /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/,
    ).uuid(),
    filename: z.string(),
    url: z.string(),
  })
  .partial()
  .passthrough();
const PresignResponse = z
  .object({ presignUrlList: z.array(PresignUrl) })
  .partial()
  .passthrough();
const FileBucketType = z.enum([
  'DriverLicence',
  'MedicareCard',
  'OtherIdentityDocument',
  'Passport',
  'CompanyExtract',
  'PartnershipAgreement',
  'TrustDeed',
  'WholesaleCertificate',
  'RealEstateSupportDocument',
  'OtherSupportDocument',
  'IdPortrait',
  'Logo',
]);
const FileItemResponse = z
  .object({
    entityId: UUID,
    bucketType: FileBucketType,
    fileId: UUID,
    filename: z.string().optional(),
    objectKey: z.string().optional(),
    lastModified: OffsetDateTime.optional(),
    sizeInBytes: z.number().int().optional(),
    docType: DocumentType.optional(),
  })
  .passthrough();
const FileItemPageResponse = z
  .object({ itemList: z.array(FileItemResponse) })
  .partial()
  .passthrough();
const LogoFileType = z.enum(['JPG', 'JPEG', 'PNG', 'SVG']);
const postV1logoorganisationCodeOrganisationCode_Body = z
  .object({ fileType: LogoFileType, file: z.instanceof(File) })
  .partial()
  .passthrough();
const LogoUploadResponse = z.object({ url: z.string() }).partial().passthrough();
const LogoResponse = z
  .object({ url: z.string(), organisationName: z.string() })
  .partial()
  .passthrough();
const AccessLevel = z.enum(['RestrictedToCustomer', 'RestrictedToClient', 'Unrestricted']);
const MetaRule: z.ZodType<MetaRule> = z.lazy(() =>
  z
    .object({ metaEntity: MetaEntity, metaAttribute: MetaAttribute, ruleType: z.string() })
    .partial()
    .passthrough(),
);
const MetaAttribute: z.ZodType<MetaAttribute> = z.lazy(() =>
  z
    .object({
      name: z.string(),
      dataType: z.string(),
      label: z.string(),
      description: z.string(),
      accessLevel: AccessLevel,
      createOnly: z.boolean(),
      readOnly: z.boolean(),
      readRoles: z.array(z.string()),
      writeRoles: z.array(z.string()),
      ruleList: z.array(MetaRule),
    })
    .partial()
    .passthrough(),
);
const MetaEntity: z.ZodType<MetaEntity> = z.lazy(() =>
  z
    .object({
      entityType: z.string(),
      attributeList: z.array(MetaAttribute),
      ruleList: z.array(MetaRule),
    })
    .partial()
    .passthrough(),
);
const Organisation: z.ZodType<Organisation> = z.lazy(() =>
  z
    .object({
      id: z.string(),
      version: z.number().int(),
      organisationType: z.literal('Client'),
      entityId: z.string(),
      organisationStatus: z.enum(['Active', 'Inactive']),
      organisationName: z.string(),
      address: Address,
      children: z.array(Organisation),
    })
    .partial()
    .passthrough(),
);
const OrganisationPageResponse = z
  .object({
    startIndex: z.number().int(),
    lastIndex: z.number().int(),
    itemCount: z.number().int(),
    itemList: z.array(Organisation),
  })
  .partial()
  .passthrough();
const OrganisationResponse = z
  .object({ organisation: Organisation, validationResults: z.array(ValidationResult) })
  .partial()
  .passthrough();
const OrganisationCode = z
  .object({
    id: z.string(),
    code: z.string(),
    organisations: z.array(Organisation),
    signUpUrl: z.string(),
    qrCodeUrl: z.string(),
    status: z.enum(['Active', 'Inactive']),
    createdDateTime: z.string(),
    createdBy: z.string(),
    lastUpdatedDateTime: z.string(),
    lastUpdatedBy: z.string(),
  })
  .partial()
  .passthrough();
const UpdateOrganisationNotificationPreferenceRequest = z
  .object({ version: z.number().int(), detail: z.string() })
  .passthrough();
const OrganisationNotificationPreferenceType = z.enum(['EMAIL', 'WEBHOOK']);
const OrganisationNotificationPreferenceStatus = z.enum(['ACTIVE', 'INACTIVE']);
const OrganisationNotificationPreferenceResponse = z
  .object({
    id: UUID.regex(
      /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/,
    ).uuid(),
    version: z.number().int(),
    organisationId: UUID.regex(
      /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/,
    ).uuid(),
    type: OrganisationNotificationPreferenceType,
    detail: z.string(),
    status: OrganisationNotificationPreferenceStatus,
  })
  .partial()
  .passthrough();
const OrgStructureSearchResult = z
  .object({
    reportingEntityUUID: z.string(),
    reportingEntityId: z.string(),
    reportingEntityName: z.string(),
    subEntityUUID: z.string(),
    subEntityId: z.string(),
    subEntityName: z.string(),
  })
  .partial()
  .passthrough();
const OrgSummarySearchResult = z
  .object({
    reportingEntityUUID: z.string(),
    reportingEntityId: z.string(),
    reportingEntityName: z.string(),
    subEntityUUID: z.string(),
    subEntityId: z.string(),
    subEntityName: z.string(),
  })
  .partial()
  .passthrough();
const OrganisationAddUserRequest = z.object({ username: z.string() }).partial().passthrough();
const CreateOrganisationCodeRequest = z
  .object({ organisationIds: z.array(z.string()) })
  .partial()
  .passthrough();
const CreateOrganisationNotificationPreferenceRequest = z
  .object({ detail: z.string() })
  .passthrough();
const CreatePartnershipRequest = z
  .object({
    entityType: z.string(),
    entityStatus: z.string(),
    partnershipName: z.string(),
    businessNumber: z.string(),
    principalPlaceOfBusiness: Address,
    partnerIds: z.array(z.string()),
  })
  .partial()
  .passthrough();
const UpdatePartnershipRequest = z
  .object({
    entityType: z.string(),
    entityStatus: z.string(),
    partnershipName: z.string(),
    businessNumber: z.string(),
    principalPlaceOfBusiness: Address,
    partnerIds: z.array(z.string()),
    id: z.string(),
    version: z.number().int(),
  })
  .partial()
  .passthrough();
const PEPSanctionPageResponse = z
  .object({
    startIndex: z.number().int(),
    lastIndex: z.number().int(),
    itemCount: z.number().int(),
    itemList: z.array(PEPSanction),
  })
  .partial()
  .passthrough();
const PEPSanctionResponse = z
  .object({ pepSanction: PEPSanction, validationResults: z.array(ValidationResult) })
  .partial()
  .passthrough();
const PermissionStatus = z.enum(['Active', 'Inactive']);
const Permission = z
  .object({
    id: UUID,
    version: z.number().int(),
    customerId: UUID,
    organisationId: UUID,
    controllerId: UUID,
    controllerName: z.string(),
    reportingEntityId: z.string(),
    reportingEntityName: z.string(),
    subEntityId: z.string(),
    subEntityName: z.string(),
    permissionStatus: PermissionStatus,
    addedDate: OffsetDateTime,
    addedBy: z.string(),
    removedDate: OffsetDateTime,
    removedBy: z.string(),
  })
  .partial()
  .passthrough();
const CreatePermissionRequest = z
  .object({
    customerId: UUID.regex(
      /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/,
    ).uuid(),
    organisationId: UUID.regex(
      /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/,
    ).uuid(),
  })
  .partial()
  .passthrough();
const PermissionResponse = z
  .object({ permission: Permission, validationResults: z.array(ValidationResult) })
  .partial()
  .passthrough();
const PermissionCustomerSummary = z
  .object({
    permissionId: z.string(),
    addedDate: z.string(),
    removedDate: z.string(),
    permissionStatus: z.string(),
    customerId: z.string(),
    entityType: z.string(),
    customerName: z.string(),
    yearsSinceRemoved: z.number().int(),
    wholesaleCertificate: z.string(),
    currentRiskTitle: z.string(),
    currentRiskScore: z.number().int(),
    lastAssessment: z.string(),
    entityStatus: z.string(),
    referenceData: z.string(),
  })
  .partial()
  .passthrough();
const PermissionCustomerSummaryPageResponse = z
  .object({
    startIndex: z.number().int(),
    lastIndex: z.number().int(),
    itemCount: z.number().int(),
    itemList: z.array(PermissionCustomerSummary),
  })
  .partial()
  .passthrough();
const PermissionRequestSummary = z
  .object({
    id: UUID.regex(
      /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/,
    ).uuid(),
    createdDateTime: OffsetDateTime.datetime({ offset: true }),
    completedDateTime: OffsetDateTime.datetime({ offset: true }),
    userEntityId: UUID.regex(
      /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/,
    ).uuid(),
    organisationId: UUID.regex(
      /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/,
    ).uuid(),
    organisationName: z.string(),
    parentOrganisationId: UUID.regex(
      /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/,
    ).uuid(),
    parentOrganisationName: z.string(),
    permissionId: UUID.regex(
      /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/,
    ).uuid(),
  })
  .partial()
  .passthrough();
const PermissionRequestRequest = z
  .object({
    userEntityId: UUID.optional(),
    username: z.string().optional(),
    email: z.string().optional(),
    organisationIdList: z.array(
      z
        .string()
        .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
        .uuid(),
    ),
  })
  .passthrough();
const PermissionRequestResponse = z
  .object({ validationResults: z.array(ValidationResult) })
  .partial()
  .passthrough();
const DocumentVerificationStatus = z.enum([
  'Pending',
  'Passed',
  'Failed',
  'SystemError',
  'DataError',
]);
const IdentityDocumentVerificationResult = z
  .object({
    verificationId: UUID.regex(
      /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/,
    ).uuid(),
    customerId: UUID.regex(
      /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/,
    ).uuid(),
    documentType: IdentityDocumentType,
    status: DocumentVerificationStatus,
  })
  .partial()
  .passthrough();
const UpdateVerificationResultRequest = z
  .object({ status: DocumentVerificationStatus })
  .partial()
  .passthrough();
const IdentityDocumentRequirement = z
  .object({ documentTypeChoices: z.array(IdentityDocumentType), documentCount: z.number().int() })
  .partial()
  .passthrough();
const IdentityDocumentVerificationStatusOverrideRequest = z
  .object({ status: z.enum(['Passed', 'Failed', 'DataError']) })
  .partial()
  .passthrough();
const LivenessCheckTransaction = z.object({ transactionId: UUID }).passthrough();
const LivenessStatus = z.enum(['NO_RESULT', 'CANNOT_CONFIRM', 'CONFIRMED']);
const LivenessTransactionWithPresignedPortraitImageUrlResponse = z
  .object({
    customerId: UUID,
    transactionId: UUID,
    checkedDateTime: OffsetDateTime,
    status: LivenessStatus,
    portraitImageUrl: z.string(),
  })
  .partial()
  .passthrough();
const LivenessTransactionWithPresignedVideoUrlResponse = z
  .object({
    customerId: UUID,
    transactionId: UUID,
    checkedDateTime: OffsetDateTime,
    videoUrl: z.string(),
  })
  .partial()
  .passthrough();
const VerificationPipelineStatus = z.enum(['Pass', 'Fail', 'Incomplete']);
const VerificationPipelineResponseItem = z
  .object({
    status: VerificationPipelineStatus,
    lastUpdatedOn: OffsetDateTime.datetime({ offset: true }),
  })
  .partial()
  .passthrough();
const VerificationPipelineResponseDocumentDetails = z
  .object({
    DriverLicence: VerificationPipelineResponseItem,
    MedicareCard: VerificationPipelineResponseItem,
    OtherIdentityDocument: VerificationPipelineResponseItem,
    Passport: VerificationPipelineResponseItem,
  })
  .partial()
  .passthrough();
const VerificationPipelineResponseItemWithDetails = z
  .object({
    status: VerificationPipelineStatus,
    lastUpdatedOn: OffsetDateTime.datetime({ offset: true }),
    details: VerificationPipelineResponseDocumentDetails,
  })
  .partial()
  .passthrough();
const VerificationPipelineResponseDocument = z
  .object({
    Capture: VerificationPipelineResponseItemWithDetails,
    DataMatch: VerificationPipelineResponseItemWithDetails,
  })
  .partial()
  .passthrough();
const VerificationPipelineResponseDocumentCategory = z
  .object({ pipeline: VerificationPipelineResponseDocument, status: VerificationPipelineStatus })
  .partial()
  .passthrough();
const VerificationPipelineResponseFaceCheck = z
  .object({
    Liveness: VerificationPipelineResponseItem,
    PhotoMatch: VerificationPipelineResponseItem,
  })
  .partial()
  .passthrough();
const VerificationPipelineResponseFaceCheckCategory = z
  .object({ pipeline: VerificationPipelineResponseFaceCheck, status: VerificationPipelineStatus })
  .partial()
  .passthrough();
const VerificationPipelineResponse = z
  .object({
    Document: VerificationPipelineResponseDocumentCategory,
    FaceCheck: VerificationPipelineResponseFaceCheckCategory,
  })
  .partial()
  .passthrough();
const CompareFacesResult = z.enum([
  'COMPLETED',
  'SOURCE_IMAGE_CONFIDENCE_LOWER_THAN_MINIMAL',
  'TARGET_IMAGE_CONFIDENCE_LOWER_THAN_MINIMAL',
  'MORE_THAN_ONE_FACE_DETECTED',
  'NO_FACE_DETECTED',
  'FAILURE',
]);
const PortraitComparisonResponse = z
  .object({ customerId: UUID, result: CompareFacesResult, similarity: z.number() })
  .partial()
  .passthrough();
const IdentityDocumentRequest = z
  .object({
    identityDocumentType: z.string(),
    driverLicence: DriverLicence,
    medicareCard: MedicareCard,
    otherIdentityDocument: OtherIdentityDocument,
    passport: Passport,
    identityDocument: IIdentityDocument,
  })
  .partial()
  .passthrough();
const IdentityPortraitResponse = z
  .object({
    customerId: UUID,
    driverLicencePortraitUrl: z.string(),
    passportPortraitUrl: z.string(),
    otherIdentityDocumentPortraitUrl: z.string(),
  })
  .partial()
  .passthrough();
const FileItem = z
  .object({
    partition: z.string(),
    bucketType: FileBucketType,
    fileId: UUID,
    filename: z.string().optional(),
    objectKey: z.string().optional(),
    lastModified: OffsetDateTime.optional(),
    sizeInBytes: z.number().int().optional(),
    docType: DocumentType.optional(),
  })
  .passthrough();
const WholesaleCertificateUpdateRequest = z
  .object({ dateIssued: z.string(), dateOfExpiry: z.string(), accountantNumber: z.string() })
  .partial()
  .passthrough();
const WholesaleCertificateCreateRequest = z.object({ fileId: z.string() }).partial().passthrough();
const WholesaleCertificateStatusRequest = z
  .object({ certificateStatus: z.string(), reason: z.string() })
  .partial()
  .passthrough();
const CountryReferenceResponse = z
  .object({ name: z.string(), alpha2: z.string(), alpha3: z.string() })
  .partial()
  .passthrough();
const RiskLevel = z.object({ title: z.string(), score: z.number().int() }).partial().passthrough();
const RiskMitigationTemplate = z
  .object({
    id: z.string(),
    version: z.number().int(),
    organisationId: z.string(),
    title: z.string(),
    description: z.string(),
    rolesRequired: z.array(z.string()),
    ocddFrequencyInMonths: z.number().int(),
    status: z.string(),
  })
  .partial()
  .passthrough();
const RuleCriteria = z
  .object({ attributeName: z.string(), comparison: z.string(), valueList: z.array(z.string()) })
  .partial()
  .passthrough();
const RiskAssessmentRule = z
  .object({
    id: z.string(),
    version: z.number().int(),
    organisationId: z.string(),
    title: z.string(),
    description: z.string(),
    inherentRisk: RiskLevel,
    residualRisk: RiskLevel,
    status: z.string(),
    mitigationList: z.array(RiskMitigationTemplate),
    criteriaList: z.array(RuleCriteria),
  })
  .partial()
  .passthrough();
const RiskMitigationComment = z
  .object({
    lastUpdatedDatetime: z.string(),
    lastUpdatedBy: z.string(),
    listIndex: z.number().int(),
    commentText: z.string(),
  })
  .partial()
  .passthrough();
const RiskMitigation = z
  .object({
    id: z.string(),
    version: z.number().int(),
    organisationId: z.string(),
    templateId: z.string(),
    title: z.string(),
    description: z.string(),
    commentList: z.array(RiskMitigationComment),
    rolesRequired: z.array(z.string()),
    lastUpdatedDatetime: z.string(),
    lastUpdatedBy: z.string(),
    mitigationStatus: z.string(),
    ocddFrequencyInMonths: z.number().int(),
    associatedRuleList: z.array(RiskAssessmentRule),
  })
  .partial()
  .passthrough();
const RiskAssessment = z
  .object({
    id: z.string(),
    version: z.number().int(),
    organisationId: z.string(),
    customerId: z.string(),
    createdDatetime: z.string(),
    lastUpdatedDatetime: z.string(),
    assessedRisk: RiskLevel,
    potentialRisk: RiskLevel,
    currentRisk: RiskLevel,
    status: z.string(),
    permissionId: z.string(),
    ruleList: z.array(RiskAssessmentRule),
    mitigations: z.array(RiskMitigation),
  })
  .partial()
  .passthrough();
const RiskAssessmentResponse = z
  .object({ riskAssessment: RiskAssessment, validationResults: z.array(ValidationResult) })
  .partial()
  .passthrough();
const RiskAssessmentPageResponse = z
  .object({
    startIndex: z.number().int(),
    lastIndex: z.number().int(),
    itemCount: z.number().int(),
    itemList: z.array(RiskAssessment),
  })
  .partial()
  .passthrough();
const RiskAssessmentRuleResponse = z
  .object({ riskAssessmentRule: RiskAssessmentRule, validationResults: z.array(ValidationResult) })
  .partial()
  .passthrough();
const RiskAssessmentRulePageResponse = z
  .object({
    startIndex: z.number().int(),
    lastIndex: z.number().int(),
    itemCount: z.number().int(),
    itemList: z.array(RiskAssessmentRule),
  })
  .partial()
  .passthrough();
const RiskAssessmentRuleTemplateRequest = z
  .object({ ruleId: z.string(), templateIdList: z.array(z.string()) })
  .partial()
  .passthrough();
const RiskAssessmentProcessRequest = z
  .object({
    clientOrgId: UUID.regex(
      /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/,
    ).uuid(),
    customerId: UUID.regex(
      /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/,
    ).uuid(),
  })
  .partial()
  .passthrough();
const RiskAssessmentSummary = z
  .object({
    riskAssessmentId: UUID.regex(
      /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/,
    ).uuid(),
    organisationId: UUID.regex(
      /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/,
    ).uuid(),
    customerId: UUID.regex(
      /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/,
    ).uuid(),
    createdDatetime: OffsetDateTime.datetime({ offset: true }),
    lastUpdatedDatetime: OffsetDateTime.datetime({ offset: true }),
    customerName: z.string(),
    entityType: z.string(),
    assessedRiskLevel: RiskLevel,
    currentRiskLevel: RiskLevel,
    assessmentStatus: z.string(),
  })
  .partial()
  .passthrough();
const PageResultRiskAssessmentSummary = z
  .object({
    startIndex: z.number().int(),
    lastIndex: z.number().int(),
    itemCount: z.number().int(),
    itemList: z.array(RiskAssessmentSummary),
  })
  .partial()
  .passthrough();
const RiskMitigationTemplateResponse = z
  .object({
    riskMitigationTemplate: RiskMitigationTemplate,
    validationResults: z.array(ValidationResult),
  })
  .partial()
  .passthrough();
const RiskMitigationTemplatePageResponse = z
  .object({
    startIndex: z.number().int(),
    lastIndex: z.number().int(),
    itemCount: z.number().int(),
    itemList: z.array(RiskMitigationTemplate),
  })
  .partial()
  .passthrough();
const RiskMitigationResponse = z
  .object({ riskMitigation: RiskMitigation, validationResults: z.array(ValidationResult) })
  .partial()
  .passthrough();
const SupportDocumentPaginationOrderBy = z.enum([
  'status',
  'documentType',
  'entityName',
  'submissionDateTime',
]);
const orderBy = z.unknown().and(SupportDocumentPaginationOrderBy).optional();
const OrderDirection = z.enum(['asc', 'desc']);
const orderDirection = z.unknown().and(OrderDirection).optional();
const EntityType = z.enum([
  'Individual_Domestic',
  'Individual_Foreign',
  'SMSF',
  'Sole_Trader',
  'Partnership',
  'Private_Company',
  'Private_Foreign_Company',
  'Public_Company',
  'Public_Foreign_Company',
  'Trust_Regulated',
  'Trust_Unregulated',
  'Charity_Association',
]);
const SupportDocumentPaginationResponse = z
  .object({
    startIndex: z.number().int(),
    lastIndex: z.number().int(),
    totalCount: z.number().int(),
    itemList: z.array(
      z
        .object({
          id: UUID,
          documentType: SupportDocumentSubType,
          entityType: EntityType,
          entityId: UUID,
          entityName: z.string(),
          status: EntityStatus,
          submissionDateTime: OffsetDateTime,
        })
        .partial()
        .passthrough(),
    ),
  })
  .partial()
  .passthrough();
const CertificateOfTitle = z
  .object({
    id: UUID,
    version: z.number().int(),
    fileId: UUID.regex(
      /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/,
    ).uuid(),
    supportDocumentSubType: SupportDocumentSubType,
    status: EntityStatus,
    createdDate: OffsetDateTime,
    createdBy: z.string(),
    lastUpdatedDate: OffsetDateTime,
    lastUpdatedBy: z.string(),
    customerId: UUID,
    keyAttributeName: z.string(),
    documentType: z.enum([
      'RealEstateSupportDocument',
      'OtherSupportDocument',
      'WholesaleCertificate',
    ]),
    dateOfIssue: LocalDate,
    volume: z.string(),
    folio: z.string(),
    descriptionOfLand: z.string(),
    propertyOwners: z.array(z.string()),
  })
  .partial()
  .passthrough();
const NationalVendorDeclarationForLivestock = z
  .object({
    id: UUID,
    version: z.number().int(),
    fileId: UUID.regex(
      /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/,
    ).uuid(),
    supportDocumentSubType: SupportDocumentSubType,
    status: EntityStatus,
    createdDate: OffsetDateTime,
    createdBy: z.string(),
    lastUpdatedDate: OffsetDateTime,
    lastUpdatedBy: z.string(),
    customerId: UUID,
    keyAttributeName: z.string(),
    documentType: z.enum([
      'RealEstateSupportDocument',
      'OtherSupportDocument',
      'WholesaleCertificate',
    ]),
    declarationDate: LocalDate,
    propertyIdentificationCode: z.string(),
  })
  .partial()
  .passthrough();
const OtherSupportDocument = z
  .object({
    id: UUID,
    version: z.number().int(),
    fileId: UUID.regex(
      /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/,
    ).uuid(),
    supportDocumentSubType: SupportDocumentSubType,
    status: EntityStatus,
    createdDate: OffsetDateTime,
    createdBy: z.string(),
    lastUpdatedDate: OffsetDateTime,
    lastUpdatedBy: z.string(),
    customerId: UUID,
    keyAttributeName: z.string(),
    documentType: z.enum([
      'RealEstateSupportDocument',
      'OtherSupportDocument',
      'WholesaleCertificate',
    ]),
    otherSupportDocumentType: z.string(),
    comment: z.string(),
  })
  .partial()
  .passthrough();
const RatesNotice = z
  .object({
    id: UUID,
    version: z.number().int(),
    fileId: UUID.regex(
      /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/,
    ).uuid(),
    supportDocumentSubType: SupportDocumentSubType,
    status: EntityStatus,
    createdDate: OffsetDateTime,
    createdBy: z.string(),
    lastUpdatedDate: OffsetDateTime,
    lastUpdatedBy: z.string(),
    customerId: UUID,
    keyAttributeName: z.string(),
    documentType: z.enum([
      'RealEstateSupportDocument',
      'OtherSupportDocument',
      'WholesaleCertificate',
    ]),
    dateOfIssue: LocalDate,
    addressOfProperty: z.string(),
    descriptionOfLand: z.string(),
    propertyOwners: z.array(z.string()),
    valuationDate: LocalDate,
    valuation: z.number().int(),
  })
  .partial()
  .passthrough();
const ValuationNotice = z
  .object({
    id: UUID,
    version: z.number().int(),
    fileId: UUID.regex(
      /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/,
    ).uuid(),
    supportDocumentSubType: SupportDocumentSubType,
    status: EntityStatus,
    createdDate: OffsetDateTime,
    createdBy: z.string(),
    lastUpdatedDate: OffsetDateTime,
    lastUpdatedBy: z.string(),
    customerId: UUID,
    keyAttributeName: z.string(),
    documentType: z.enum([
      'RealEstateSupportDocument',
      'OtherSupportDocument',
      'WholesaleCertificate',
    ]),
    landValueAsAt: LocalDate,
    landValue: z.number().int(),
    ownersOrLessees: z.array(z.string()),
    addressOfProperty: z.string(),
    descriptionOfLand: z.string(),
  })
  .partial()
  .passthrough();
const SupportDocument = z.union([
  CertificateOfTitle,
  NationalVendorDeclarationForLivestock,
  OtherSupportDocument,
  RatesNotice,
  ValuationNotice,
  WholesaleCertificate,
]);
const CreateSupportDocumentRequest = z
  .object({
    supportDocumentSubType: SupportDocumentSubType,
    fileId: UUID.regex(
      /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/,
    ).uuid(),
  })
  .partial()
  .passthrough();
const SupportDocumentSubTypeWithAllowedStatus = z
  .object({ supportDocumentSubType: SupportDocumentSubType, allowed: z.boolean() })
  .partial()
  .passthrough();
const UpdateSupportDocumentRequest = z
  .object({
    fileId: UUID.regex(
      /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/,
    ).uuid(),
  })
  .partial()
  .passthrough();
const UpdateSupportDocumentStatusRequest = z
  .object({ status: EntityStatus, reason: z.string() })
  .partial()
  .passthrough();
const TaskType = z.enum(['FACE_CHECK', 'WHOLESALE_CERTIFICATE', 'REVIEW_REJECTED_IDENTITY']);
const TaskConfiguration = z.object({}).partial().passthrough();
const TaskStatus = z.enum(['ACTIVE', 'INACTIVE', 'FAILED', 'SUCCEEDED']);
const TaskLookupResponse = z
  .object({
    id: UUID,
    customerId: UUID,
    type: TaskType,
    configuration: TaskConfiguration,
    status: TaskStatus,
    createdDateTime: OffsetDateTime,
    createdBy: z.string(),
    trackingReferenceUrl: z.string(),
  })
  .partial()
  .passthrough();
const TaskCreationRequest = z
  .object({ type: TaskType, configuration: TaskConfiguration.optional() })
  .passthrough();
const TaskCreatedResponse = z
  .object({
    id: UUID,
    customerId: UUID,
    type: TaskType,
    configuration: TaskConfiguration,
    status: TaskStatus,
    createdDateTime: OffsetDateTime,
    createdBy: z.string(),
  })
  .partial()
  .passthrough();
const EmptyTaskCreationRequest = z.object({}).partial().passthrough();
const TrackingReferenceType = z.enum(['TASK', 'SIGN_UP']);
const TrackingReferenceActivationResponse = z
  .object({ type: TrackingReferenceType, taskId: UUID.optional() })
  .passthrough();
const CreateTrustRequest = z
  .object({
    entityType: z.string(),
    entityStatus: z.string(),
    address: Address,
    phone: Phone,
    businessNumber: z.string(),
    trustName: z.string(),
    businessNameOfTrustee: z.string(),
    trustType: z.string(),
    ultimateBeneficialOwnerIds: z.array(z.string()),
    trusteeIds: z.array(z.string()),
    settlorIds: z.array(z.string()),
  })
  .partial()
  .passthrough();
const UpdateTrustRequest = z
  .object({
    entityType: z.string(),
    entityStatus: z.string(),
    address: Address,
    phone: Phone,
    businessNumber: z.string(),
    trustName: z.string(),
    businessNameOfTrustee: z.string(),
    trustType: z.string(),
    ultimateBeneficialOwnerIds: z.array(z.string()),
    trusteeIds: z.array(z.string()),
    settlorIds: z.array(z.string()),
    id: z.string(),
    version: z.number().int(),
  })
  .partial()
  .passthrough();
const CreateUserRequest = z
  .object({
    preferredName: z.string(),
    email: z.string(),
    organisationId: z.string(),
    sendInvite: z.boolean(),
  })
  .partial()
  .passthrough();
const UserResponse = z
  .object({ userName: z.string(), roles: z.array(z.string()) })
  .partial()
  .passthrough();
const UserInviteRequest = z
  .object({ email: z.string(), code: z.string(), referenceData: z.string().optional() })
  .passthrough();
const UserInviteResponse = z
  .object({ trackingReference: z.string(), inviteUrl: z.string(), qrCodeUrl: z.string() })
  .partial()
  .passthrough();
const UserCredentialSetupStatusUpdateRequest = z
  .object({ withCredentials: z.boolean() })
  .partial()
  .passthrough();
const SelfSignUpRequest = z
  .object({ email: z.string(), code: z.string(), reference: z.string().optional() })
  .passthrough();
const SelfSignUpResponse = z
  .object({ trackingReference: z.string(), invitationSent: z.boolean() })
  .partial()
  .passthrough();
const InvitationTrackingActionRequest = z.object({ email: z.string() }).partial().passthrough();
const InvitationTrackingAction = z.enum(['MAGIC_LINK', 'LOGIN']);
const InvitationTrackingActionResponse = z
  .object({ action: InvitationTrackingAction, trackingReference: z.string() })
  .partial()
  .passthrough();
const TrackingReferenceStatus = z.enum(['Active', 'Inactive', 'Completed']);
const TrackingReferenceStatusResponse = z
  .object({ status: TrackingReferenceStatus })
  .partial()
  .passthrough();
const SignupEmailRequest = z
  .object({ preferredName: z.string(), email: z.string(), organisationId: z.string() })
  .partial()
  .passthrough();
const CustomerPaginationOrderBy = z.enum(['status', 'name', 'createdDate', 'updatedDate']);
const orderBy__2 = z.unknown().and(CustomerPaginationOrderBy).optional();
const CustomerPaginationResponse = z
  .object({
    beginIndex: z.number().int(),
    endIndex: z.number().int(),
    total: z.number().int(),
    customers: z.array(
      z
        .object({
          id: UUID,
          type: EntityType,
          status: EntityStatus,
          name: z.string(),
          email: z.string(),
          createdDate: OffsetDateTime,
          updatedDate: OffsetDateTime,
        })
        .partial()
        .passthrough(),
    ),
  })
  .partial()
  .passthrough();
const OrganisationCreateRequest = z
  .object({ organisationName: z.string(), parentEntityId: z.string().optional() })
  .passthrough();
const CreatePEPSanctionRequest = z
  .object({ pepStatus: z.boolean(), sanctionStatus: z.boolean(), commentText: z.string() })
  .passthrough();
const CreatePersonRequest = z
  .object({ entityStatus: z.string(), person: Person, addToUser: z.boolean() })
  .partial()
  .passthrough();
const UpdatePersonRequest = z
  .object({ entityStatus: z.string(), person: Person })
  .partial()
  .passthrough();
const UpdatePortraitRequest = z
  .object({
    fileId: UUID.regex(
      /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/,
    ).uuid(),
  })
  .partial()
  .passthrough();
const IdentityPortraitItemResponse = z
  .object({ customerId: UUID, portraitUrl: z.string() })
  .partial()
  .passthrough();

export const schemas = {
  UUID,
  Address,
  Phone,
  CreateCompanyRequest,
  OffsetDateTime,
  PEPSanction,
  DriverLicence,
  MedicareCard,
  OtherIdentityDocument,
  Passport,
  SupportDocumentSubType,
  EntityStatus,
  LocalDate,
  WholesaleCertificate,
  IdentityDocumentType,
  AuthorizedIssuer,
  IIdentityDocument,
  Person,
  PersonReference,
  Company,
  CustomerReference,
  Trust,
  SoleTrader,
  Partnership,
  Customer,
  ResultType,
  ResultScope,
  ValidationResult,
  CustomerResponse,
  UpdateCompanyRequest,
  DocumentType,
  postV1companyIdcompanyExtract_Body,
  CustomerListResponse,
  CustomerRequest,
  ReviewerUserCustomerRole,
  SyncReviewersRequest,
  IValidatable,
  ValidationFailedResponse,
  UserCustomerRole,
  WalletHolderUserCustomerRole,
  SyncWalletHoldersRequest,
  UserCustomerRoleSource,
  UserCustomerRoleResponse,
  UpdateEntityStatusRequest,
  UpdateIdentityDocumentStatusRequest,
  CustomerAddUserRoleRequest,
  ScanResultStatus,
  ScanResultErrorDetail,
  IdentityDocumentScanResult,
  ScanDocumentApiResponse,
  postV1file_Body,
  FileUploadResponse,
  FileType,
  PresignApiRequest,
  PresignUrl,
  PresignResponse,
  FileBucketType,
  FileItemResponse,
  FileItemPageResponse,
  LogoFileType,
  postV1logoorganisationCodeOrganisationCode_Body,
  LogoUploadResponse,
  LogoResponse,
  AccessLevel,
  MetaRule,
  MetaAttribute,
  MetaEntity,
  Organisation,
  OrganisationPageResponse,
  OrganisationResponse,
  OrganisationCode,
  UpdateOrganisationNotificationPreferenceRequest,
  OrganisationNotificationPreferenceType,
  OrganisationNotificationPreferenceStatus,
  OrganisationNotificationPreferenceResponse,
  OrgStructureSearchResult,
  OrgSummarySearchResult,
  OrganisationAddUserRequest,
  CreateOrganisationCodeRequest,
  CreateOrganisationNotificationPreferenceRequest,
  CreatePartnershipRequest,
  UpdatePartnershipRequest,
  PEPSanctionPageResponse,
  PEPSanctionResponse,
  PermissionStatus,
  Permission,
  CreatePermissionRequest,
  PermissionResponse,
  PermissionCustomerSummary,
  PermissionCustomerSummaryPageResponse,
  PermissionRequestSummary,
  PermissionRequestRequest,
  PermissionRequestResponse,
  DocumentVerificationStatus,
  IdentityDocumentVerificationResult,
  UpdateVerificationResultRequest,
  IdentityDocumentRequirement,
  IdentityDocumentVerificationStatusOverrideRequest,
  LivenessCheckTransaction,
  LivenessStatus,
  LivenessTransactionWithPresignedPortraitImageUrlResponse,
  LivenessTransactionWithPresignedVideoUrlResponse,
  VerificationPipelineStatus,
  VerificationPipelineResponseItem,
  VerificationPipelineResponseDocumentDetails,
  VerificationPipelineResponseItemWithDetails,
  VerificationPipelineResponseDocument,
  VerificationPipelineResponseDocumentCategory,
  VerificationPipelineResponseFaceCheck,
  VerificationPipelineResponseFaceCheckCategory,
  VerificationPipelineResponse,
  CompareFacesResult,
  PortraitComparisonResponse,
  IdentityDocumentRequest,
  IdentityPortraitResponse,
  FileItem,
  WholesaleCertificateUpdateRequest,
  WholesaleCertificateCreateRequest,
  WholesaleCertificateStatusRequest,
  CountryReferenceResponse,
  RiskLevel,
  RiskMitigationTemplate,
  RuleCriteria,
  RiskAssessmentRule,
  RiskMitigationComment,
  RiskMitigation,
  RiskAssessment,
  RiskAssessmentResponse,
  RiskAssessmentPageResponse,
  RiskAssessmentRuleResponse,
  RiskAssessmentRulePageResponse,
  RiskAssessmentRuleTemplateRequest,
  RiskAssessmentProcessRequest,
  RiskAssessmentSummary,
  PageResultRiskAssessmentSummary,
  RiskMitigationTemplateResponse,
  RiskMitigationTemplatePageResponse,
  RiskMitigationResponse,
  SupportDocumentPaginationOrderBy,
  orderBy,
  OrderDirection,
  orderDirection,
  EntityType,
  SupportDocumentPaginationResponse,
  CertificateOfTitle,
  NationalVendorDeclarationForLivestock,
  OtherSupportDocument,
  RatesNotice,
  ValuationNotice,
  SupportDocument,
  CreateSupportDocumentRequest,
  SupportDocumentSubTypeWithAllowedStatus,
  UpdateSupportDocumentRequest,
  UpdateSupportDocumentStatusRequest,
  TaskType,
  TaskConfiguration,
  TaskStatus,
  TaskLookupResponse,
  TaskCreationRequest,
  TaskCreatedResponse,
  EmptyTaskCreationRequest,
  TrackingReferenceType,
  TrackingReferenceActivationResponse,
  CreateTrustRequest,
  UpdateTrustRequest,
  CreateUserRequest,
  UserResponse,
  UserInviteRequest,
  UserInviteResponse,
  UserCredentialSetupStatusUpdateRequest,
  SelfSignUpRequest,
  SelfSignUpResponse,
  InvitationTrackingActionRequest,
  InvitationTrackingAction,
  InvitationTrackingActionResponse,
  TrackingReferenceStatus,
  TrackingReferenceStatusResponse,
  SignupEmailRequest,
  CustomerPaginationOrderBy,
  orderBy__2,
  CustomerPaginationResponse,
  OrganisationCreateRequest,
  CreatePEPSanctionRequest,
  CreatePersonRequest,
  UpdatePersonRequest,
  UpdatePortraitRequest,
  IdentityPortraitItemResponse,
};

const endpoints = makeApi([
  {
    method: 'post',
    path: '/v1/company',
    alias: 'postV1company',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateCompanyRequest,
      },
    ],
    response: CustomerResponse,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'put',
    path: '/v1/company/:id',
    alias: 'putV1companyId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: UpdateCompanyRequest,
      },
      {
        name: 'id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: CustomerResponse,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/company/:id',
    alias: 'getV1companyId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: Company,
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/company/:id/companyExtract',
    alias: 'postV1companyIdcompanyExtract',
    requestFormat: 'form-data',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postV1companyIdcompanyExtract_Body,
      },
      {
        name: 'id',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: CustomerResponse,
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/customer',
    alias: 'getV1customer',
    description: `List of Customers with a specific status`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'entityStatus',
        type: 'Query',
        schema: z.enum(['Draft', 'InProgress', 'Rejected', 'Verified']),
      },
      {
        name: 'entityType',
        type: 'Query',
        schema: z.enum([
          'Individual_Domestic',
          'Individual_Foreign',
          'SMSF',
          'Sole_Trader',
          'Partnership',
          'Private_Company',
          'Private_Foreign_Company',
          'Public_Company',
          'Public_Foreign_Company',
          'Trust_Regulated',
          'Trust_Unregulated',
          'Charity_Association',
        ]),
      },
      {
        name: 'resolveReferences',
        type: 'Query',
        schema: z.boolean().optional().default(true),
      },
    ],
    response: CustomerListResponse,
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'patch',
    path: '/v1/customer/:customerId/user/roles/reviewer',
    alias: 'patchV1customerCustomerIduserrolesreviewer',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: SyncReviewersRequest,
      },
      {
        name: 'customerId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: z.array(ReviewerUserCustomerRole),
    errors: [
      {
        status: 400,
        description: `The request is invalid due to missing or incorrect parameters.`,
        schema: ValidationFailedResponse,
      },
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
      {
        status: 405,
        description: `The customer identity is verified and cannot be reviewed, or the customer identity is an individual or sole trader type that does not allow additional reviewers.`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/customer/:customerId/user/roles/reviewer',
    alias: 'getV1customerCustomerIduserrolesreviewer',
    requestFormat: 'json',
    parameters: [
      {
        name: 'customerId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: z.array(ReviewerUserCustomerRole),
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/customer/:customerId/user/roles/reviewer',
    alias: 'postV1customerCustomerIduserrolesreviewer',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ReviewerUserCustomerRole,
      },
      {
        name: 'customerId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: z.array(ReviewerUserCustomerRole),
    errors: [
      {
        status: 400,
        description: `The request is invalid due to missing or incorrect parameters.`,
        schema: ValidationFailedResponse,
      },
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
      {
        status: 405,
        description: `The customer identity is verified and cannot be reviewed, or the customer identity is an individual or sole trader type that does not allow additional reviewers.`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'patch',
    path: '/v1/customer/:customerId/user/roles/wallet',
    alias: 'patchV1customerCustomerIduserroleswallet',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: SyncWalletHoldersRequest,
      },
      {
        name: 'customerId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: z.array(WalletHolderUserCustomerRole),
    errors: [
      {
        status: 400,
        description: `The request is invalid due to missing or incorrect parameters.`,
        schema: ValidationFailedResponse,
      },
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
      {
        status: 405,
        description: `the customer identity is an individual or sole trader type that does not allow additional reviewers.`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/customer/:customerId/user/roles/wallet',
    alias: 'getV1customerCustomerIduserroleswallet',
    description: `Find all users who will hold the specified identity in their account after the identity has been verified. A user can hold an identity in their account if they are a controller/viewer the of the specified customer. The actual role is derived from customer reference and assigned by the system or assigned directly by the administrator.`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'customerId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: z.array(WalletHolderUserCustomerRole),
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/customer/:customerId/user/roles/wallet',
    alias: 'postV1customerCustomerIduserroleswallet',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: WalletHolderUserCustomerRole,
      },
      {
        name: 'customerId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: z.array(WalletHolderUserCustomerRole),
    errors: [
      {
        status: 400,
        description: `The request is invalid due to missing or incorrect parameters.`,
        schema: ValidationFailedResponse,
      },
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
      {
        status: 405,
        description: `the customer identity is an individual or sole trader type that does not allow additional reviewers.`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/customer/:customerId/userRole',
    alias: 'getV1customerCustomerIduserRole',
    description: `    List of all the users with the specified customer in their wallet and the roles they have in
    relation to the customer.
`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'customerId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
      {
        name: 'includeCustomerReference',
        type: 'Query',
        schema: z.boolean().optional().default(false),
      },
    ],
    response: z.array(UserCustomerRoleResponse),
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/customer/:id',
    alias: 'getV1customerId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
      {
        name: 'withPepSanction',
        type: 'Query',
        schema: z.boolean().optional(),
      },
    ],
    response: Customer,
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'delete',
    path: '/v1/customer/:id',
    alias: 'deleteV1customerId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: CustomerResponse,
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'put',
    path: '/v1/customer/:id/byAdmin',
    alias: 'putV1customerIdbyAdmin',
    description: `The admin can make various different changes to the customer. The admin can also choose to send an
entityStatus change at the same time, or they can choose to update just the entity status
at a later time using the updateEntityStatus() method below.
`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `The update customer request`,
        type: 'Body',
        schema: CustomerRequest,
      },
      {
        name: 'id',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: CustomerResponse,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'delete',
    path: '/v1/customer/:id/check',
    alias: 'deleteV1customerIdcheck',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: CustomerResponse,
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'put',
    path: '/v1/customer/:id/entityStatus',
    alias: 'putV1customerIdentityStatus',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: UpdateEntityStatusRequest,
      },
      {
        name: 'id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: CustomerResponse,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'put',
    path: '/v1/customer/:id/identityDocumentStatus',
    alias: 'putV1customerIdidentityDocumentStatus',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: UpdateIdentityDocumentStatusRequest,
      },
      {
        name: 'id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: CustomerResponse,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'delete',
    path: '/v1/customer/:id/user',
    alias: 'deleteV1customerIduser',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
      {
        name: 'email',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'sources',
        type: 'Query',
        schema: z.array(z.any()).optional(),
      },
      {
        name: 'username',
        type: 'Query',
        schema: z.string().optional(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 400,
        description: `The request is invalid due to missing or incorrect parameters.`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/customer/:id/user',
    alias: 'postV1customerIduser',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CustomerAddUserRoleRequest,
      },
      {
        name: 'id',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 400,
        description: `The request is invalid due to missing or incorrect parameters.`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/customer/byAdmin',
    alias: 'postV1customerbyAdmin',
    description: `The admin can create all types of customer, but must supply the email address of an existing user.
At the moment we do not support Admins creating customers for users that don&#x27;t exist. The admin
can also choose to send an entityStatus change at the same time, or they can choose to update
just the entity status at a later time using the updateEntityStatus() method below.
`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `The create customer request`,
        type: 'Body',
        schema: CustomerRequest,
      },
    ],
    response: CustomerResponse,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/customer/me',
    alias: 'getV1customerme',
    requestFormat: 'json',
    parameters: [
      {
        name: 'recursive',
        type: 'Query',
        schema: z.boolean().optional().default(true),
      },
    ],
    response: CustomerListResponse,
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/customer/user',
    alias: 'getV1customeruser',
    description: `    List of all the customers in the wallet of the specified user.
`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'email',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'recursive',
        type: 'Query',
        schema: z.boolean().optional().default(true),
      },
    ],
    response: CustomerListResponse,
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/document/scan/result/customer/:customerId/identityDocument',
    alias: 'getV1documentscanresultcustomerCustomerIdidentityDocument',
    description: `Find identity document scan results for the customer specified by id.`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'customerId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
      {
        name: 'documentType',
        type: 'Query',
        schema: z.enum(['DriverLicence', 'MedicareCard', 'Passport']).optional(),
      },
      {
        name: 'latestOnly',
        type: 'Query',
        schema: z.boolean().optional().default(true),
      },
    ],
    response: z.array(IdentityDocumentScanResult),
    errors: [
      {
        status: 401,
        description: `User must be authenticated to access this endpoint.`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `User must have the admin role to access this endpoint.`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Specified customer not found`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/document/scan/result/customer/:customerId/identityDocument/:documentType/scan',
    alias: 'getV1documentscanresultcustomerCustomerIdidentityDocumentDocumentTypescan',
    description: `Manually trigger identity document scanning for the customer specified by id and identity document type.`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'customerId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
      {
        name: 'documentType',
        type: 'Path',
        schema: z.enum(['DriverLicence', 'MedicareCard', 'Passport']),
      },
    ],
    response: ScanDocumentApiResponse,
    errors: [
      {
        status: 401,
        description: `User must be authenticated to access this endpoint.`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `User must have the admin role to access this endpoint.`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Specified customer or document not found OR otherIdentityDocument type has been specified.`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/file',
    alias: 'postV1file',
    requestFormat: 'form-data',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postV1file_Body,
      },
    ],
    response: FileUploadResponse,
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/file/:entityId/:docType',
    alias: 'getV1fileEntityIdDocType',
    requestFormat: 'json',
    parameters: [
      {
        name: 'docType',
        type: 'Path',
        schema: z.enum([
          'DriverLicence',
          'MedicareCard',
          'OtherIdentityDocument',
          'Passport',
          'CompanyExtract',
          'PartnershipAgreement',
          'TrustDeed',
          'WholesaleCertificate',
          'RealEstateSupportDocument',
          'OtherSupportDocument',
        ]),
      },
      {
        name: 'entityId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: FileItemPageResponse,
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'delete',
    path: '/v1/file/:entityId/:docType/:fileId',
    alias: 'deleteV1fileEntityIdDocTypeFileId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'docType',
        type: 'Path',
        schema: z.enum([
          'DriverLicence',
          'MedicareCard',
          'OtherIdentityDocument',
          'Passport',
          'CompanyExtract',
          'PartnershipAgreement',
          'TrustDeed',
          'WholesaleCertificate',
          'RealEstateSupportDocument',
          'OtherSupportDocument',
        ]),
      },
      {
        name: 'entityId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
      {
        name: 'fileId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/file/:entityId/:docType/:fileId/presignDownload',
    alias: 'getV1fileEntityIdDocTypeFileIdpresignDownload',
    requestFormat: 'json',
    parameters: [
      {
        name: 'docType',
        type: 'Path',
        schema: z.enum([
          'DriverLicence',
          'MedicareCard',
          'OtherIdentityDocument',
          'Passport',
          'CompanyExtract',
          'PartnershipAgreement',
          'TrustDeed',
          'WholesaleCertificate',
          'RealEstateSupportDocument',
          'OtherSupportDocument',
        ]),
      },
      {
        name: 'entityId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
      {
        name: 'fileId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: PresignUrl,
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/file/presign',
    alias: 'postV1filepresign',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: PresignApiRequest,
      },
    ],
    response: PresignResponse,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/logo/organisationCode/:organisationCode',
    alias: 'postV1logoorganisationCodeOrganisationCode',
    requestFormat: 'form-data',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postV1logoorganisationCodeOrganisationCode_Body,
      },
      {
        name: 'organisationCode',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.object({ url: z.string() }).partial().passthrough(),
    errors: [
      {
        status: 400,
        description: `Validation failed`,
        schema: ValidationFailedResponse,
      },
      {
        status: 401,
        description: `User must be authenticated to access this endpoint.`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Only admin or client users are allowed to upload logo for organisation.`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/logo/organisationCode/:organisationCode',
    alias: 'getV1logoorganisationCodeOrganisationCode',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organisationCode',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: LogoResponse,
    errors: [
      {
        status: 404,
        description: `No logo found with the given organisation code`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/logo/tracking/:trackingReference',
    alias: 'getV1logotrackingTrackingReference',
    requestFormat: 'json',
    parameters: [
      {
        name: 'trackingReference',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: LogoResponse,
    errors: [
      {
        status: 404,
        description: `No logo found with the given tracking id`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/meta',
    alias: 'getV1meta',
    requestFormat: 'json',
    response: z.record(MetaEntity),
  },
  {
    method: 'get',
    path: '/v1/meta/:name',
    alias: 'getV1metaName',
    requestFormat: 'json',
    parameters: [
      {
        name: 'name',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: MetaEntity,
  },
  {
    method: 'get',
    path: '/v1/meta/error',
    alias: 'getV1metaerror',
    requestFormat: 'json',
    response: z.record(z.record(z.string())),
  },
  {
    method: 'get',
    path: '/v1/organisation',
    alias: 'getV1organisation',
    description: `Find all organisations based on the query parameters. The query parameters are used to filter, sort and paginate the results.`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'lastIndex',
        type: 'Query',
        schema: z.number().int().optional().default(24),
      },
      {
        name: 'level',
        type: 'Query',
        schema: z.enum(['All', 'ChildOnly', 'ParentOnly']).optional().default('All'),
      },
      {
        name: 'orderBy',
        type: 'Query',
        schema: z.literal('organisationName').optional().default('organisationName'),
      },
      {
        name: 'orderDirection',
        type: 'Query',
        schema: z.enum(['asc', 'desc']).optional().default('asc'),
      },
      {
        name: 'startIndex',
        type: 'Query',
        schema: z.number().int().optional().default(0),
      },
    ],
    response: OrganisationPageResponse,
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/organisation',
    alias: 'postV1organisation',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: Organisation,
      },
    ],
    response: OrganisationResponse,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `Bearer token is not specified or incorrect`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Admin role required`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'put',
    path: '/v1/organisation/:id',
    alias: 'putV1organisationId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: Organisation,
      },
      {
        name: 'id',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: OrganisationResponse,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/organisation/:id',
    alias: 'getV1organisationId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: Organisation,
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'patch',
    path: '/v1/organisation/:id/deactivate',
    alias: 'patchV1organisationIddeactivate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/organisation/:id/user',
    alias: 'postV1organisationIduser',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({ username: z.string() }).partial().passthrough(),
      },
      {
        name: 'id',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'patch',
    path: '/v1/organisation/:ofChildEntityId/parent/:toParentEntityId',
    alias: 'patchV1organisationOfChildEntityIdparentToParentEntityId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'ofChildEntityId',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'toParentEntityId',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: OrganisationResponse,
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/organisation/:organisationId/code',
    alias: 'getV1organisationOrganisationIdcode',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organisationId',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'includeInactive',
        type: 'Query',
        schema: z.boolean().optional().default(false),
      },
    ],
    response: z.array(OrganisationCode),
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/organisation/:organisationId/code',
    alias: 'postV1organisationOrganisationIdcode',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateOrganisationCodeRequest,
      },
      {
        name: 'organisationId',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: OrganisationCode,
    errors: [
      {
        status: 400,
        description: `Validation error`,
        schema: z.array(ValidationResult),
      },
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/organisation/:organisationId/notification/preferences',
    alias: 'getV1organisationOrganisationIdnotificationpreferences',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organisationId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: z.array(OrganisationNotificationPreferenceResponse),
    errors: [
      {
        status: 401,
        description: `User is not authenticated`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `User is not allowed read the organisation notification preferences`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/organisation/:organisationId/notification/preferences/type/:type',
    alias: 'postV1organisationOrganisationIdnotificationpreferencestypeType',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        name: 'organisationId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
      {
        name: 'type',
        type: 'Path',
        schema: z.enum(['EMAIL', 'WEBHOOK']),
      },
    ],
    response: OrganisationNotificationPreferenceResponse,
    errors: [
      {
        status: 400,
        description: `Invalid request body`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `User is not authenticated`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `User is not allowed read the organisation notification preferences`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'patch',
    path: '/v1/organisation/code/:organisationCode/deactivate',
    alias: 'patchV1organisationcodeOrganisationCodedeactivate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organisationCode',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/organisation/file/:entityId/export',
    alias: 'getV1organisationfileEntityIdexport',
    requestFormat: 'json',
    parameters: [
      {
        name: 'entityId',
        type: 'Path',
        schema: z.unknown(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/organisation/file/import',
    alias: 'postV1organisationfileimport',
    requestFormat: 'form-data',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z
          .object({ file: z.instanceof(File) })
          .partial()
          .passthrough(),
      },
    ],
    response: OrganisationResponse,
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/organisation/me',
    alias: 'getV1organisationme',
    requestFormat: 'json',
    response: z.array(Organisation),
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/organisation/me/code',
    alias: 'getV1organisationmecode',
    requestFormat: 'json',
    parameters: [
      {
        name: 'includeInactive',
        type: 'Query',
        schema: z.boolean().optional().default(false),
      },
    ],
    response: z.array(OrganisationCode),
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'put',
    path: '/v1/organisation/notification/preferences/:preferenceId',
    alias: 'putV1organisationnotificationpreferencesPreferenceId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: UpdateOrganisationNotificationPreferenceRequest,
      },
      {
        name: 'preferenceId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: OrganisationNotificationPreferenceResponse,
    errors: [
      {
        status: 400,
        description: `Invalid request body`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `User is not authenticated`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `User is not allowed update the organisation notification preferences`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Notification preference not found`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'patch',
    path: '/v1/organisation/notification/preferences/:preferenceId/deactivate',
    alias: 'patchV1organisationnotificationpreferencesPreferenceIddeactivate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'preferenceId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: OrganisationNotificationPreferenceResponse,
    errors: [
      {
        status: 401,
        description: `User is not authenticated`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `User is not allowed update the organisation notification preferences`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Notification preference not found`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/organisation/structure',
    alias: 'getV1organisationstructure',
    requestFormat: 'json',
    response: z.array(OrgStructureSearchResult),
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/organisation/summary',
    alias: 'getV1organisationsummary',
    requestFormat: 'json',
    parameters: [
      {
        name: 'criteria',
        type: 'Query',
        schema: z.string().optional(),
      },
    ],
    response: z.array(OrgSummarySearchResult),
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/organisation/summary/customer/:customerId',
    alias: 'getV1organisationsummarycustomerCustomerId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'customerId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
      {
        name: 'criteria',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'ignoreStatus',
        type: 'Query',
        schema: z.boolean().optional().default(false),
      },
    ],
    response: z.array(OrgSummarySearchResult),
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/partnership',
    alias: 'postV1partnership',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreatePartnershipRequest,
      },
    ],
    response: CustomerResponse,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'put',
    path: '/v1/partnership/:id',
    alias: 'putV1partnershipId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: UpdatePartnershipRequest,
      },
      {
        name: 'id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: CustomerResponse,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/pepsanction/customer/:customerId',
    alias: 'getV1pepsanctioncustomerCustomerId',
    description: `Use the /v2/pepsanction/customer/{customerId} endpoint instead`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'customerId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: PEPSanctionPageResponse,
    errors: [
      {
        status: 401,
        description: `User must be authenticated to access this endpoint.`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `User must have the client or admin role to access this endpoint, further more if the user is a client user, the target customer must have permissioned the client organisation of the user.`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/pepsanction/customer/:customerId',
    alias: 'postV1pepsanctioncustomerCustomerId',
    description: `Use the /v2/pepsanction/customer/{customerId} endpoint instead`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: PEPSanction,
      },
      {
        name: 'customerId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: PEPSanctionResponse,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `User must be authenticated to access this endpoint.`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `User must have the admin role to access this endpoint.`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/pepsanction/customer/:customerId/latest',
    alias: 'getV1pepsanctioncustomerCustomerIdlatest',
    requestFormat: 'json',
    parameters: [
      {
        name: 'customerId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: PEPSanction,
    errors: [
      {
        status: 401,
        description: `User must be authenticated to access this endpoint.`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `User must have the client or admin role to access this endpoint, further more if the user is a client user, the target customer must have permissioned the client organisation of the user.`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `The customer specified by customerId was not found`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/permission',
    alias: 'getV1permission',
    requestFormat: 'json',
    parameters: [
      {
        name: 'customerId',
        type: 'Query',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid()
          .optional(),
      },
    ],
    response: z.array(Permission),
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/permission',
    alias: 'postV1permission',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreatePermissionRequest,
      },
    ],
    response: PermissionResponse,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'delete',
    path: '/v1/permission/:id',
    alias: 'deleteV1permissionId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/permission/organisation/:orgId',
    alias: 'getV1permissionorganisationOrgId',
    description: `This method finds a list of Permissions for a given CLIENT. The caller must have the admin or client role to execute this query.`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'orgId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
      {
        name: 'entityType',
        type: 'Query',
        schema: z
          .enum([
            'Individual_Domestic',
            'Individual_Foreign',
            'SMSF',
            'Sole_Trader',
            'Partnership',
            'Private_Company',
            'Private_Foreign_Company',
            'Public_Company',
            'Public_Foreign_Company',
            'Trust_Regulated',
            'Trust_Unregulated',
            'Charity_Association',
          ])
          .optional(),
      },
      {
        name: 'format',
        type: 'Query',
        schema: z.enum(['json', 'xlsx']).optional().default('json'),
      },
      {
        name: 'fromDate',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'lastAssessedFromDate',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'lastAssessedToDate',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'lastIndex',
        type: 'Query',
        schema: z.number().int().optional().default(24),
      },
      {
        name: 'name',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'orderBy',
        type: 'Query',
        schema: z
          .enum([
            'name',
            'status',
            'entityType',
            'wholesaleCertificateStatus',
            'currentRiskTitle',
            'currentRiskScore',
            'lastAssessment',
            'referenceData',
          ])
          .optional()
          .default('name'),
      },
      {
        name: 'orderDirection',
        type: 'Query',
        schema: z.enum(['asc', 'desc']).optional().default('asc'),
      },
      {
        name: 'permissionFromDate',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'permissionToDate',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'referenceData',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'riskLevel',
        type: 'Query',
        schema: z.enum(['Low', 'Medium', 'High', 'Extreme']).optional(),
      },
      {
        name: 'startIndex',
        type: 'Query',
        schema: z.number().int().optional().default(0),
      },
      {
        name: 'status',
        type: 'Query',
        schema: z.string().optional().default('Active'),
      },
      {
        name: 'statuses',
        type: 'Query',
        schema: z.array(z.enum(['Active', 'Inactive'])).optional(),
      },
      {
        name: 'toDate',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'wholesaleCertificateStatus',
        type: 'Query',
        schema: z.enum(['None', 'InProgress', 'Rejected', 'Verified']).optional(),
      },
      {
        name: 'xlsxTimeZoneId',
        type: 'Query',
        schema: z.string().optional().default('Australia/Sydney'),
      },
    ],
    response: PermissionCustomerSummaryPageResponse,
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `The caller does not have permission to execute this query`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/permission/request',
    alias: 'getV1permissionrequest',
    requestFormat: 'json',
    parameters: [
      {
        name: 'email',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'userId',
        type: 'Query',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid()
          .optional(),
      },
      {
        name: 'username',
        type: 'Query',
        schema: z.string().optional(),
      },
    ],
    response: z.array(PermissionRequestSummary),
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/permission/request',
    alias: 'postV1permissionrequest',
    description: `Create a permission request for a user to permission an organisation.`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: PermissionRequestRequest,
      },
    ],
    response: PermissionRequestResponse,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'delete',
    path: '/v1/permission/request/:id',
    alias: 'deleteV1permissionrequestId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/permission/request/me',
    alias: 'getV1permissionrequestme',
    requestFormat: 'json',
    response: z.array(PermissionRequestSummary),
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/person',
    alias: 'postV1person',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: Person,
      },
      {
        name: 'addToUser',
        type: 'Query',
        schema: z.boolean().optional(),
      },
    ],
    response: CustomerResponse,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/person/:customerId/identityDocument/:documentType/verification',
    alias: 'postV1personCustomerIdidentityDocumentDocumentTypeverification',
    requestFormat: 'json',
    parameters: [
      {
        name: 'customerId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
      {
        name: 'documentType',
        type: 'Path',
        schema: z.enum(['Passport', 'DriverLicence', 'OtherIdentityDocument', 'MedicareCard']),
      },
    ],
    response: IdentityDocumentVerificationResult,
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `The customer with the given ID was not found`,
        schema: z.void(),
      },
      {
        status: 409,
        description: `The customer already has a pending verification result for the given document type`,
        schema: IdentityDocumentVerificationResult,
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/person/:customerId/identityDocument/:documentType/verification',
    alias: 'getV1personCustomerIdidentityDocumentDocumentTypeverification',
    requestFormat: 'json',
    parameters: [
      {
        name: 'customerId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
      {
        name: 'documentType',
        type: 'Path',
        schema: z.enum(['Passport', 'DriverLicence', 'OtherIdentityDocument', 'MedicareCard']),
      },
    ],
    response: z.array(IdentityDocumentVerificationResult),
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/person/:customerId/identityDocument/:documentType/verification/current',
    alias: 'getV1personCustomerIdidentityDocumentDocumentTypeverificationcurrent',
    requestFormat: 'json',
    parameters: [
      {
        name: 'customerId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
      {
        name: 'documentType',
        type: 'Path',
        schema: z.enum(['Passport', 'DriverLicence', 'OtherIdentityDocument', 'MedicareCard']),
      },
    ],
    response: IdentityDocumentVerificationResult.nullable(),
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'put',
    path: '/v1/person/:customerId/identityDocument/:documentType/verification/status',
    alias: 'putV1personCustomerIdidentityDocumentDocumentTypeverificationstatus',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `The status override request`,
        type: 'Body',
        schema: IdentityDocumentVerificationStatusOverrideRequest,
      },
      {
        name: 'customerId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
      {
        name: 'documentType',
        type: 'Path',
        schema: z.enum(['Passport', 'DriverLicence', 'OtherIdentityDocument', 'MedicareCard']),
      },
    ],
    response: IdentityDocumentVerificationResult,
    errors: [
      {
        status: 400,
        description: `The request was invalid`,
        schema: ValidationFailedResponse,
      },
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `The customer with the given ID was not found`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/person/:customerId/liveness/transaction',
    alias: 'postV1personCustomerIdlivenesstransaction',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `The liveness check transaction to associate with the person`,
        type: 'Body',
        schema: LivenessCheckTransaction,
      },
      {
        name: 'customerId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 400,
        description: `The request was invalid`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `User must be authenticated to access this endpoint.`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `User must have the customer role and must be able to update the customer specified by customerId to access this endpoint.`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `The liveness check transaction was not found`,
        schema: z.void(),
      },
      {
        status: 409,
        description: `The liveness check transaction is associated with the wrong person`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/person/:customerId/liveness/transaction/latest',
    alias: 'getV1personCustomerIdlivenesstransactionlatest',
    requestFormat: 'json',
    parameters: [
      {
        name: 'customerId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: LivenessTransactionWithPresignedPortraitImageUrlResponse,
    errors: [
      {
        status: 401,
        description: `User must be authenticated to access this endpoint.`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `User must have the admin or client role, and client must have the rights to read customer (i.e. permission granted to client.`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `No liveness check transaction was available for the specified customer.`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/person/:customerId/liveness/transaction/latest/video',
    alias: 'getV1personCustomerIdlivenesstransactionlatestvideo',
    requestFormat: 'json',
    parameters: [
      {
        name: 'customerId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: LivenessTransactionWithPresignedVideoUrlResponse,
    errors: [
      {
        status: 401,
        description: `User must be authenticated to access this endpoint.`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `User must have the admin or client role, and client must have the rights to read customer (i.e. permission granted to client.`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `No liveness check transaction was available for the specified customer, or video is not available (e.g. due to licence error).`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/person/:customerId/organisation/:organisationId/verification/pipeline',
    alias: 'getV1personCustomerIdorganisationOrganisationIdverificationpipeline',
    requestFormat: 'json',
    parameters: [
      {
        name: 'customerId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
      {
        name: 'organisationId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: VerificationPipelineResponse,
    errors: [
      {
        status: 401,
        description: `User is not authenticated`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `User is not authorized to view detail of the customer`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `No customer found`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/person/:customerId/overall/verification/pipeline',
    alias: 'getV1personCustomerIdoverallverificationpipeline',
    description: `Note: This is an admin only endpoint.`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'customerId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: VerificationPipelineResponse,
    errors: [
      {
        status: 401,
        description: `User is not authenticated`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `User is not authorized to view detail of the customer`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `No customer found`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/person/:customerId/portrait/comparison',
    alias: 'getV1personCustomerIdportraitcomparison',
    requestFormat: 'json',
    parameters: [
      {
        name: 'customerId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: PortraitComparisonResponse,
    errors: [
      {
        status: 401,
        description: `User must be authenticated to access this endpoint.`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `User must have the admin or client role, and client must have the rights to read customer (i.e. permission granted to client.`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `No portrait face comparison result for the specified customer.`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/person/:customerId/verification/pipeline',
    alias: 'getV1personCustomerIdverificationpipeline',
    description: `Note: This is deprecated. Please use either /v1/person/{customerId}/organisation/{orgId}/verification/pipeline or /v1/person/{customerId}/overall/verification/pipeline`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'customerId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: VerificationPipelineResponse,
    errors: [
      {
        status: 401,
        description: `User is not authenticated`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `User is not authorized to view detail of the customer`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `No customer found`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'put',
    path: '/v1/person/:id',
    alias: 'putV1personId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: Person,
      },
      {
        name: 'id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: CustomerResponse,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/person/:id',
    alias: 'getV1personId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: Person,
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'put',
    path: '/v1/person/:id/identityDocument',
    alias: 'putV1personIdidentityDocument',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: IdentityDocumentRequest,
      },
      {
        name: 'id',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: CustomerResponse,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/person/:id/identityDocument/portrait',
    alias: 'getV1personIdidentityDocumentportrait',
    description: `Get portrait urls of identity documents for an individual.  If the individual has multiple identity documents, the portrait urls of all identity documents will be returned.`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: IdentityPortraitResponse,
    errors: [
      {
        status: 401,
        description: `User is not authenticated`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `User is not authorized to view detail of the individual`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'put',
    path: '/v1/person/:id/verificationDocuments',
    alias: 'putV1personIdverificationDocuments',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.array(DocumentType),
      },
      {
        name: 'id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/person/:id/verificationDocuments',
    alias: 'getV1personIdverificationDocuments',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.array(FileItem),
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'put',
    path: '/v1/person/:id/wholesaleCertificate',
    alias: 'putV1personIdwholesaleCertificate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: WholesaleCertificateUpdateRequest,
      },
      {
        name: 'id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: CustomerResponse,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/person/:id/wholesaleCertificate',
    alias: 'postV1personIdwholesaleCertificate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({ fileId: z.string() }).partial().passthrough(),
      },
      {
        name: 'id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: CustomerResponse,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'put',
    path: '/v1/person/:id/wholesaleCertificate/certificateStatus',
    alias: 'putV1personIdwholesaleCertificatecertificateStatus',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: WholesaleCertificateStatusRequest,
      },
      {
        name: 'id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: CustomerResponse,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/person/identityDocument/verification/:verificationId',
    alias: 'getV1personidentityDocumentverificationVerificationId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'verificationId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: IdentityDocumentVerificationResult,
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `No verification found with the given ID`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'put',
    path: '/v1/person/identityDocument/verification/:verificationId/status',
    alias: 'putV1personidentityDocumentverificationVerificationIdstatus',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: UpdateVerificationResultRequest,
      },
      {
        name: 'verificationId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: IdentityDocumentVerificationResult,
    errors: [
      {
        status: 400,
        description: `The request was invalid`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `No verification found with the given ID`,
        schema: z.void(),
      },
      {
        status: 409,
        description: `The status of the verification result cannot be updated to the requested status`,
        schema: IdentityDocumentVerificationResult,
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/person/me/identityDocument/requirement',
    alias: 'getV1personmeidentityDocumentrequirement',
    description: `Get identity document requirement for an individual onboarding to VerifiMe client organisation. The requirement is determined by the client organisation, and should only be called for the purpose of initial onboarding process (i.e. Individual Wizard workflow).  For additional risk assessment mitigation,  or failed verification, the requirement should be derived from upload document task.`,
    requestFormat: 'json',
    response: IdentityDocumentRequirement,
    errors: [
      {
        status: 401,
        description: `User is not authenticated`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `User is not authorized to be onboarded`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/reference/countries',
    alias: 'getV1referencecountries',
    requestFormat: 'json',
    response: z.array(CountryReferenceResponse),
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/reference/countries/search',
    alias: 'getV1referencecountriessearch',
    requestFormat: 'json',
    parameters: [
      {
        name: 'term',
        type: 'Query',
        schema: z.string().optional(),
      },
    ],
    response: z.array(CountryReferenceResponse),
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/risk/assessment',
    alias: 'postV1riskassessment',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: RiskAssessment,
      },
    ],
    response: RiskAssessmentResponse,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'put',
    path: '/v1/risk/assessment/:id',
    alias: 'putV1riskassessmentId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: RiskAssessment,
      },
      {
        name: 'id',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: RiskAssessmentResponse,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/risk/assessment/:id',
    alias: 'getV1riskassessmentId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: RiskAssessment,
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'delete',
    path: '/v1/risk/assessment/:id',
    alias: 'deleteV1riskassessmentId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'put',
    path: '/v1/risk/assessment/:riskAssessmentId/mitigation/:riskMitigationId/status/:status',
    alias: 'putV1riskassessmentRiskAssessmentIdmitigationRiskMitigationIdstatusStatus',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: RiskMitigationComment,
      },
      {
        name: 'riskAssessmentId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
      {
        name: 'riskMitigationId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
      {
        name: 'status',
        type: 'Path',
        schema: z.enum(['Open', 'Pass', 'Fail', 'Ignore']),
      },
    ],
    response: RiskAssessment,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/risk/assessment/organisation/:orgId',
    alias: 'getV1riskassessmentorganisationOrgId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'orgId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
      {
        name: 'assessedRiskLevel',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'assessmentStatus',
        type: 'Query',
        schema: z.enum(['NoRisksDetected', 'Open', 'Closed']).optional(),
      },
      {
        name: 'currentRiskLevel',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'customerId',
        type: 'Query',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid()
          .optional(),
      },
      {
        name: 'customerName',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'entityType',
        type: 'Query',
        schema: z
          .enum([
            'Individual_Domestic',
            'Individual_Foreign',
            'SMSF',
            'Sole_Trader',
            'Partnership',
            'Private_Company',
            'Private_Foreign_Company',
            'Public_Company',
            'Public_Foreign_Company',
            'Trust_Regulated',
            'Trust_Unregulated',
            'Charity_Association',
          ])
          .optional(),
      },
      {
        name: 'fromDateCreated',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'fromLastUpdated',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'lastIndex',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'orderBy',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'orderDirection',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'startIndex',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'toDateCreated',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'toLastUpdated',
        type: 'Query',
        schema: z.string().optional(),
      },
    ],
    response: RiskAssessmentPageResponse,
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/risk/assessment/rule',
    alias: 'postV1riskassessmentrule',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: RiskAssessmentRule,
      },
    ],
    response: RiskAssessmentRuleResponse,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'put',
    path: '/v1/risk/assessment/rule/:id',
    alias: 'putV1riskassessmentruleId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: RiskAssessmentRule,
      },
      {
        name: 'id',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: RiskAssessmentRuleResponse,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/risk/assessment/rule/:id',
    alias: 'getV1riskassessmentruleId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: RiskAssessmentRule,
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'delete',
    path: '/v1/risk/assessment/rule/:id',
    alias: 'deleteV1riskassessmentruleId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: RiskAssessmentRuleResponse,
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'delete',
    path: '/v1/risk/assessment/rule/:id/check',
    alias: 'deleteV1riskassessmentruleIdcheck',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: RiskAssessmentRuleResponse,
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'put',
    path: '/v1/risk/assessment/rule/:id/status/:status',
    alias: 'putV1riskassessmentruleIdstatusStatus',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
      {
        name: 'status',
        type: 'Path',
        schema: z.enum(['Active', 'Inactive']),
      },
    ],
    response: RiskAssessmentRuleResponse,
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'delete',
    path: '/v1/risk/assessment/rule/:id/template',
    alias: 'deleteV1riskassessmentruleIdtemplate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: RiskAssessmentRuleTemplateRequest,
      },
      {
        name: 'id',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: RiskAssessmentRuleResponse,
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'put',
    path: '/v1/risk/assessment/rule/:id/template',
    alias: 'putV1riskassessmentruleIdtemplate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: RiskAssessmentRuleTemplateRequest,
      },
      {
        name: 'id',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: RiskAssessmentRuleResponse,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/risk/assessment/rule/organisation/:orgId',
    alias: 'getV1riskassessmentruleorganisationOrgId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'orgId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
      {
        name: 'ignoreStatus',
        type: 'Query',
        schema: z.boolean().optional().default(false),
      },
      {
        name: 'lastIndex',
        type: 'Query',
        schema: z.number().int().optional().default(24),
      },
      {
        name: 'startIndex',
        type: 'Query',
        schema: z.number().int().optional().default(0),
      },
    ],
    response: RiskAssessmentRulePageResponse,
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/risk/assessment/run',
    alias: 'postV1riskassessmentrun',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: RiskAssessmentProcessRequest,
      },
    ],
    response: RiskAssessment,
    errors: [
      {
        status: 400,
        description: `Validation error`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/risk/assessment/run/check',
    alias: 'postV1riskassessmentruncheck',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: RiskAssessmentProcessRequest,
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Process not allowed to run. The customer has not permissioned the client organisation.`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/risk/assessment/summary/organisation/:orgId',
    alias: 'getV1riskassessmentsummaryorganisationOrgId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'orgId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
      {
        name: 'assessedRiskLevel',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'assessmentStatus',
        type: 'Query',
        schema: z.enum(['NoRisksDetected', 'Open', 'Closed']).optional(),
      },
      {
        name: 'currentRiskLevel',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'customerId',
        type: 'Query',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid()
          .optional(),
      },
      {
        name: 'customerName',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'entityType',
        type: 'Query',
        schema: z
          .enum([
            'Individual_Domestic',
            'Individual_Foreign',
            'SMSF',
            'Sole_Trader',
            'Partnership',
            'Private_Company',
            'Private_Foreign_Company',
            'Public_Company',
            'Public_Foreign_Company',
            'Trust_Regulated',
            'Trust_Unregulated',
            'Charity_Association',
          ])
          .optional(),
      },
      {
        name: 'fromDateCreated',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'fromLastUpdated',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'lastIndex',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'orderBy',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'orderDirection',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'startIndex',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'toDateCreated',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'toLastUpdated',
        type: 'Query',
        schema: z.string().optional(),
      },
    ],
    response: PageResultRiskAssessmentSummary,
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'put',
    path: '/v1/risk/mitigation/:id',
    alias: 'putV1riskmitigationId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: RiskMitigation,
      },
      {
        name: 'id',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: RiskMitigationResponse,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/risk/mitigation/:id',
    alias: 'getV1riskmitigationId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: RiskMitigation,
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'put',
    path: '/v1/risk/mitigation/:id/comment',
    alias: 'putV1riskmitigationIdcomment',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: RiskMitigationComment,
      },
      {
        name: 'id',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: RiskMitigation,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'put',
    path: '/v1/risk/mitigation/:id/status/:status',
    alias: 'putV1riskmitigationIdstatusStatus',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: RiskMitigationComment,
      },
      {
        name: 'id',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
      {
        name: 'status',
        type: 'Path',
        schema: z.enum(['Open', 'Pass', 'Fail', 'Ignore']),
      },
    ],
    response: RiskMitigation,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/risk/mitigation/template',
    alias: 'postV1riskmitigationtemplate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: RiskMitigationTemplate,
      },
    ],
    response: RiskMitigationTemplateResponse,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'put',
    path: '/v1/risk/mitigation/template/:id',
    alias: 'putV1riskmitigationtemplateId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: RiskMitigationTemplate,
      },
      {
        name: 'id',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: RiskMitigationTemplateResponse,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/risk/mitigation/template/:id',
    alias: 'getV1riskmitigationtemplateId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: RiskMitigationTemplate,
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'delete',
    path: '/v1/risk/mitigation/template/:id',
    alias: 'deleteV1riskmitigationtemplateId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: RiskMitigationTemplateResponse,
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'delete',
    path: '/v1/risk/mitigation/template/:id/check',
    alias: 'deleteV1riskmitigationtemplateIdcheck',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: RiskMitigationTemplateResponse,
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'put',
    path: '/v1/risk/mitigation/template/:id/status/:status',
    alias: 'putV1riskmitigationtemplateIdstatusStatus',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
      {
        name: 'status',
        type: 'Path',
        schema: z.enum(['Active', 'Inactive']),
      },
    ],
    response: RiskMitigationTemplateResponse,
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/risk/mitigation/template/organisation/:orgId',
    alias: 'getV1riskmitigationtemplateorganisationOrgId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'orgId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
      {
        name: 'ignoreStatus',
        type: 'Query',
        schema: z.boolean().optional().default(false),
      },
      {
        name: 'lastIndex',
        type: 'Query',
        schema: z.number().int().optional().default(24),
      },
      {
        name: 'startIndex',
        type: 'Query',
        schema: z.number().int().optional().default(0),
      },
    ],
    response: RiskMitigationTemplatePageResponse,
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/soletrader',
    alias: 'postV1soletrader',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: SoleTrader,
      },
    ],
    response: CustomerResponse,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'put',
    path: '/v1/soletrader/:id',
    alias: 'putV1soletraderId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: SoleTrader,
      },
      {
        name: 'id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: CustomerResponse,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/support_document',
    alias: 'getV1support_document',
    requestFormat: 'json',
    parameters: [
      {
        name: 'documentType',
        type: 'Query',
        schema: z
          .enum([
            'CertificateOfTitle',
            'NationalVendorDeclarationForLivestock',
            'Other',
            'RatesNotice',
            'ValuationNotice',
            'WholesaleCertificate',
          ])
          .optional(),
      },
      {
        name: 'entityName',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'lastIndex',
        type: 'Query',
        schema: z.number().int().optional().default(24),
      },
      {
        name: 'orderBy',
        type: 'Query',
        schema: orderBy,
      },
      {
        name: 'orderDirection',
        type: 'Query',
        schema: orderDirection,
      },
      {
        name: 'startIndex',
        type: 'Query',
        schema: z.number().int().optional().default(0),
      },
      {
        name: 'status',
        type: 'Query',
        schema: z.enum(['Draft', 'InProgress', 'Rejected', 'Verified']).optional(),
      },
    ],
    response: SupportDocumentPaginationResponse,
    errors: [
      {
        status: 401,
        description: `User must be authenticated to access this endpoint.`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `User must have the admin role to access this endpoint.`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'put',
    path: '/v1/support_document/:supportDocumentId',
    alias: 'putV1support_documentSupportDocumentId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: UpdateSupportDocumentRequest,
      },
      {
        name: 'supportDocumentId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: SupportDocument,
    errors: [
      {
        status: 400,
        description: `Invalid request`,
        schema: z.array(ValidationResult),
      },
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Support document not found`,
        schema: z.array(ValidationResult),
      },
      {
        status: 409,
        description: `Support document update conflict occurred`,
        schema: SupportDocument,
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/support_document/:supportDocumentId',
    alias: 'getV1support_documentSupportDocumentId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'supportDocumentId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: SupportDocument,
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Support document not found`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'delete',
    path: '/v1/support_document/:supportDocumentId',
    alias: 'deleteV1support_documentSupportDocumentId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'supportDocumentId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Support document not found`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'put',
    path: '/v1/support_document/:supportDocumentId/byAdmin',
    alias: 'putV1support_documentSupportDocumentIdbyAdmin',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: SupportDocument,
      },
      {
        name: 'supportDocumentId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: SupportDocument,
    errors: [
      {
        status: 400,
        description: `Invalid request`,
        schema: z.array(ValidationResult),
      },
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Support document not found`,
        schema: z.array(ValidationResult),
      },
      {
        status: 409,
        description: `Support document update conflict occurred`,
        schema: SupportDocument,
      },
    ],
  },
  {
    method: 'put',
    path: '/v1/support_document/:supportDocumentId/status',
    alias: 'putV1support_documentSupportDocumentIdstatus',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: UpdateSupportDocumentStatusRequest,
      },
      {
        name: 'supportDocumentId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: SupportDocument,
    errors: [
      {
        status: 400,
        description: `Invalid request`,
        schema: ValidationFailedResponse,
      },
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Support document not found`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/support_document/customer/:entityId',
    alias: 'getV1support_documentcustomerEntityId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'entityId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: z.array(SupportDocument),
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Customer entity not found`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/support_document/customer/:entityId',
    alias: 'postV1support_documentcustomerEntityId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateSupportDocumentRequest,
      },
      {
        name: 'entityId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: SupportDocument,
    errors: [
      {
        status: 400,
        description: `Invalid request`,
        schema: z.array(ValidationResult),
      },
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Customer entity or file not found`,
        schema: z.array(ValidationResult),
      },
      {
        status: 409,
        description: `Support document already exists`,
        schema: z.array(ValidationResult),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/support_document/customer/:entityId/sub_types',
    alias: 'getV1support_documentcustomerEntityIdsub_types',
    requestFormat: 'json',
    parameters: [
      {
        name: 'entityId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: z.array(SupportDocumentSubTypeWithAllowedStatus),
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Customer entity not found`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/task/:id',
    alias: 'getV1taskId',
    description: `This method shows task information`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: TaskLookupResponse,
    errors: [
      {
        status: 401,
        description: `User must be authenticated to access this endpoint.`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Only users with admin or client role is allowed.`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Customer specified by customerId could not be found.`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/task/:type/customer/:customerId',
    alias: 'postV1taskTypecustomerCustomerId',
    description: `This method creates a task of a customer. Note: this API is deprecated`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: 'customerId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
      {
        name: 'type',
        type: 'Path',
        schema: z.enum(['FACE_CHECK', 'WHOLESALE_CERTIFICATE', 'REVIEW_REJECTED_IDENTITY']),
      },
    ],
    response: TaskCreatedResponse,
    errors: [
      {
        status: 400,
        description: `Invalid task type.`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `User must be authenticated to access this endpoint.`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Only users with admin or client role is allowed.`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Customer specified by customerId could not be found.`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/task/customer/:customerId',
    alias: 'getV1taskcustomerCustomerId',
    description: `This method list all tasks of a customer`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'customerId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
      {
        name: 'type',
        type: 'Query',
        schema: z
          .enum(['FACE_CHECK', 'WHOLESALE_CERTIFICATE', 'REVIEW_REJECTED_IDENTITY'])
          .optional(),
      },
    ],
    response: z.array(TaskLookupResponse),
    errors: [
      {
        status: 401,
        description: `User must be authenticated to access this endpoint.`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Only users with admin or client role is allowed.`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Customer specified by customerId could not be found.`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/task/customer/:customerId',
    alias: 'postV1taskcustomerCustomerId',
    description: `This method creates a task of a customer.`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: TaskCreationRequest,
      },
      {
        name: 'customerId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: TaskCreatedResponse,
    errors: [
      {
        status: 400,
        description: `Invalid task type.`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `User must be authenticated to access this endpoint.`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Only users with admin or client role is allowed.`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Customer specified by customerId could not be found.`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'patch',
    path: '/v1/tracking/:trackingReference/activate',
    alias: 'patchV1trackingTrackingReferenceactivate',
    description: `Activate a tracking reference, if the tracking reference is not already activated.`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'trackingReference',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: TrackingReferenceActivationResponse,
    errors: [
      {
        status: 400,
        description: `Validation error`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `User is not authenticated`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `User is not allowed to activate tracking reference for another user`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Tracking reference not found`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/trust',
    alias: 'postV1trust',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateTrustRequest,
      },
    ],
    response: CustomerResponse,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'put',
    path: '/v1/trust/:id',
    alias: 'putV1trustId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: UpdateTrustRequest,
      },
      {
        name: 'id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: CustomerResponse,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/trust/:id',
    alias: 'getV1trustId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: Trust,
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/trust/:id/deed',
    alias: 'postV1trustIddeed',
    requestFormat: 'form-data',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postV1companyIdcompanyExtract_Body,
      },
      {
        name: 'id',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: CustomerResponse,
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/user/customer',
    alias: 'postV1usercustomer',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateUserRequest,
      },
    ],
    response: UserResponse,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/user/invite',
    alias: 'postV1userinvite',
    description: `Invite a user to the system, note this API is intended for use by client organisations who wishes to integrate with VerifiMe.`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: UserInviteRequest,
      },
    ],
    response: UserInviteResponse,
    errors: [
      {
        status: 400,
        description: `Validation error in the request when invalid email or organisation code is provided`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/user/me',
    alias: 'getV1userme',
    requestFormat: 'json',
    response: UserResponse,
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'patch',
    path: '/v1/user/me/credentialSetupStatus',
    alias: 'patchV1usermecredentialSetupStatus',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({ withCredentials: z.boolean() }).partial().passthrough(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
      {
        status: 406,
        description: `The user is not allowed to reset the credential setup status to false`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/user/primaryIndividual',
    alias: 'getV1userprimaryIndividual',
    description: `Lookup the primary individual identity of a user by email or username.`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'email',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'username',
        type: 'Query',
        schema: z.string().optional(),
      },
    ],
    response: Person,
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `The user does not exist.`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/user/selfsignup/tracking',
    alias: 'postV1userselfsignuptracking',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: SelfSignUpRequest,
      },
    ],
    response: SelfSignUpResponse,
    errors: [
      {
        status: 400,
        description: `Validation error`,
        schema: z.array(ValidationResult),
      },
    ],
  },
  {
    method: 'patch',
    path: '/v1/user/selfsignup/tracking/:trackingReference/activate',
    alias: 'patchV1userselfsignuptrackingTrackingReferenceactivate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'trackingReference',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 400,
        description: `Validation error`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/user/signup/tracking/:trackingReference/action',
    alias: 'postV1usersignuptrackingTrackingReferenceaction',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({ email: z.string() }).partial().passthrough(),
      },
      {
        name: 'trackingReference',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: InvitationTrackingActionResponse,
    errors: [
      {
        status: 400,
        description: `Validation error`,
        schema: z.array(ValidationResult),
      },
    ],
  },
  {
    method: 'patch',
    path: '/v1/user/signup/tracking/:trackingReference/activate',
    alias: 'patchV1usersignuptrackingTrackingReferenceactivate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'trackingReference',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 400,
        description: `Validation error`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/user/signup/tracking/:trackingReference/status',
    alias: 'getV1usersignuptrackingTrackingReferencestatus',
    requestFormat: 'json',
    parameters: [
      {
        name: 'trackingReference',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: TrackingReferenceStatusResponse,
    errors: [
      {
        status: 400,
        description: `Invalid tracking reference supplied.`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/user/signupInvite',
    alias: 'postV1usersignupInvite',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: SignupEmailRequest,
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/user/username',
    alias: 'getV1userusername',
    requestFormat: 'json',
    parameters: [
      {
        name: 'email',
        type: 'Query',
        schema: z.string().optional(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `User does not exist.`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v2/customer',
    alias: 'getV2customer',
    requestFormat: 'json',
    parameters: [
      {
        name: 'beginIndex',
        type: 'Query',
        schema: z.number().int().optional().default(0),
      },
      {
        name: 'endIndex',
        type: 'Query',
        schema: z.number().int().optional().default(24),
      },
      {
        name: 'name',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'orderBy',
        type: 'Query',
        schema: orderBy__2,
      },
      {
        name: 'orderDirection',
        type: 'Query',
        schema: orderDirection,
      },
      {
        name: 'status',
        type: 'Query',
        schema: z.enum(['Draft', 'InProgress', 'Rejected', 'Verified']).optional(),
      },
      {
        name: 'type',
        type: 'Query',
        schema: z
          .enum([
            'Individual_Domestic',
            'Individual_Foreign',
            'SMSF',
            'Sole_Trader',
            'Partnership',
            'Private_Company',
            'Private_Foreign_Company',
            'Public_Company',
            'Public_Foreign_Company',
            'Trust_Regulated',
            'Trust_Unregulated',
            'Charity_Association',
          ])
          .optional(),
      },
    ],
    response: CustomerPaginationResponse,
    errors: [
      {
        status: 401,
        description: `User must be authenticated to access this endpoint.`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `User must have the admin role to access this endpoint.`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v2/organisation',
    alias: 'postV2organisation',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: OrganisationCreateRequest,
      },
    ],
    response: OrganisationResponse,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `Bearer token is not specified or incorrect`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Admin role required`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v2/organisation/clone/from/:fromSubEntityId/to/:toSubEntityId',
    alias: 'postV2organisationclonefromFromSubEntityIdtoToSubEntityId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'fromSubEntityId',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'toSubEntityId',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 400,
        description: `Entity IDs must start with &#x27;SE-&#x27;, which are sub-entity entity IDs`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Organisation with entity id specified by fromSubEntityId or toSubEntityId not found`,
        schema: z.void(),
      },
      {
        status: 409,
        description: `Sub-entity of toSubEntityId already has rules and mitigation templates`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v2/pepsanction/customer/:customerId',
    alias: 'getV2pepsanctioncustomerCustomerId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'customerId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: z.array(PEPSanction),
    errors: [
      {
        status: 401,
        description: `User must be authenticated to access this endpoint.`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `User must have the client or admin role to access this endpoint, further more if the user is a client user, the target customer must have permissioned the client organisation of the user.`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v2/pepsanction/customer/:customerId',
    alias: 'postV2pepsanctioncustomerCustomerId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreatePEPSanctionRequest,
      },
      {
        name: 'customerId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: PEPSanction,
    errors: [
      {
        status: 400,
        description: `The request was invalid.`,
        schema: z.array(ValidationResult),
      },
      {
        status: 401,
        description: `User must be authenticated to access this endpoint.`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `User must have the admin role to access this endpoint.`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `The customer specified by customerId was not found`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v2/pepsanction/customer/:customerId/latest',
    alias: 'getV2pepsanctioncustomerCustomerIdlatest',
    requestFormat: 'json',
    parameters: [
      {
        name: 'customerId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: PEPSanction.nullable(),
    errors: [
      {
        status: 401,
        description: `User must be authenticated to access this endpoint.`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `User must have the client or admin role to access this endpoint, further more if the user is a client user, the target customer must have permissioned the client organisation of the user.`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `The customer specified by customerId was not found`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v2/person',
    alias: 'postV2person',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreatePersonRequest,
      },
    ],
    response: CustomerResponse,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v2/person/:customerId/liveness/transaction/latest',
    alias: 'getV2personCustomerIdlivenesstransactionlatest',
    requestFormat: 'json',
    parameters: [
      {
        name: 'customerId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: LivenessTransactionWithPresignedPortraitImageUrlResponse.nullable(),
    errors: [
      {
        status: 401,
        description: `User must be authenticated to access this endpoint.`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `User must have the admin or client role, and client must have the rights to read customer (i.e. permission granted to client.`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `No customer was found with the specified ID.`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v2/person/:customerId/liveness/transaction/latest/video',
    alias: 'getV2personCustomerIdlivenesstransactionlatestvideo',
    requestFormat: 'json',
    parameters: [
      {
        name: 'customerId',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: LivenessTransactionWithPresignedVideoUrlResponse.nullable(),
    errors: [
      {
        status: 401,
        description: `User must be authenticated to access this endpoint.`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `User must have the admin or client role, and client must have the rights to read customer (i.e. permission granted to client.`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `No customer found with specified ID`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'put',
    path: '/v2/person/:id',
    alias: 'putV2personId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: UpdatePersonRequest,
      },
      {
        name: 'id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: CustomerResponse,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `Not Authorized`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Not Allowed`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v2/person/:id/portrait',
    alias: 'postV2personIdportrait',
    description: `Manual update portrait urls for an individual`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: UpdatePortraitRequest,
      },
      {
        name: 'id',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: IdentityPortraitItemResponse,
    errors: [
      {
        status: 400,
        description: `FileId is not supplied`,
        schema: z.void(),
      },
      {
        status: 401,
        description: `User is not authenticated`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `User is not authorized to update portrait`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v2/person/:id/portrait',
    alias: 'getV2personIdportrait',
    description: `Get the latest portrait url of an individual, no matter what type is`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z
          .string()
          .regex(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          .uuid(),
      },
    ],
    response: IdentityPortraitItemResponse,
    errors: [
      {
        status: 401,
        description: `User is not authenticated`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `User is not authorized to view detail of the individual`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Portrait is not found`,
        schema: z.void(),
      },
    ],
  },
]);

export const api = new Zodios(endpoints);

export function createApiClient(baseUrl: string, options?: ZodiosOptions) {
  return new Zodios(baseUrl, endpoints, options);
}
